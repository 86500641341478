import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { text } from 'utils/colors'
import { head, words, startsWith } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Spinner from 'components/Spinner'

interface AvatarProps {
  user: AvatarUser
  isImageOnly?: boolean
  secondaryText?: string | React.ReactElement<any>
  status?: 'COMPLIANT' | 'NON_COMPLIANT' | 'NOT_APPLICABLE' | 'PENDING' | 'OVERDUE'
  isTitle?: boolean
  isSmall?: boolean
  isCircleSmall?: boolean
  isInvite?: boolean
  isExpired?: boolean
  isRemoved?: boolean
}

interface AvatarUser {
  id?: string
  name?: string
  email?: string
  picture?: string
}

interface Container {
  isTitle?: boolean
  isSmall?: boolean
  isFormer?: boolean
  isCircleSmall?: boolean
}

const AvatarContainer = styled.div<Container>`
  display: flex;
  flex-direction: ${p => (p.isTitle ? 'row-reverse' : 'row')};
  align-items: center;
  opacity: ${p => (p.isFormer ? '0.4' : '1')};
  font-size: ${(p: Container) => (p.isSmall ? '0.7rem' : '1rem')};
`
const CircleWrapper = styled.div`
  font-size: ${(p: Container) => (p.isCircleSmall ? '0.8em' : '1.2em')};
`
const AvatarImageBorder = styled.div<{ borderColor: string }>`
  border-radius: 1.5em;
  border: 0.25em solid ${p => p.borderColor};
`
export const SVGWrapper = styled.div``
const AvatarImage = styled.div<AvatarUser>`
  border-radius: 1.5em;
  width: 2.5em;
  height: 2.5em;
  background-image: url(${p => p.picture});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
const AvatarText = styled.div`
  margin: 0 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`
const TopText = styled.div`
  font-weight: 600;
`
const BottomText = styled.div`
  margin-top: 0.1em;
  font-weight: 200;
  font-size: 0.9rem;
`
const UserInitials = styled.text`
  text-transform: uppercase;
  fill: ${text.contrast};
  text-anchor: middle;
  font-weight: 700;
  font-size: 1.25em;
`
const TitlebarText = styled.div`
  font-weight: 200;
  & > strong {
    font-weight: 400;
  }
`
const InviteMsg = styled.span`
  color: ${text.warning};
`
const ExpiredRemovedMsg = styled.span`
  color: ${text.error};
`
const InviteIcon = styled.svg`
  color: ${text.contrast};
`

const Avatar: React.FC<AvatarProps> = ({
  user,
  isImageOnly,
  secondaryText,
  status,
  isTitle,
  isSmall,
  isCircleSmall,
  isInvite,
  isExpired,
  isRemoved,
}: AvatarProps) => {
  const { t } = useTranslation()

  if (user === undefined) return <Spinner isMini />

  const primaryLine = user.name || user.email || 'UNKNOWN'
  const secondaryLine = secondaryText != null ? secondaryText : null

  const randomHashColor = () => {
    if (!user.email) return '#aaa'
    let hash = 0
    for (let i = 0; i < user.email.length; i++) {
      hash = user.email.charCodeAt(i) + ((hash << 5) - hash)
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.substr(-2)
    }
    return color
  }

  const getUserInitials = () => {
    // if a group or a one word name return first initial
    // if a 3 word user name return 3 initials
    // otherwise return first and last initial
    const words = primaryLine.split(' ')
    if (!words.length || words.length < 2) {
      return primaryLine.charAt(0).toLowerCase()
    } else {
      const first = words[0].charAt(0).toLowerCase()
      const last = words[words.length - 1].charAt(0).toLowerCase()
      const mid = words[1].charAt(0).toLowerCase()
      return `${first}${words.length === 3 ? mid : ''}${last}`
    }
  }

  const borderColor = status != null ? text[status] : 'transparent'
  const circleText = isInvite ? (
    <InviteIcon width="1.5em" height="1.5em" x="0.65em" y="0.75em">
      <FontAwesomeIcon icon="paper-plane" />
    </InviteIcon>
  ) : (
    <UserInitials x="1.2em" y="1.55em">
      {getUserInitials()}
    </UserInitials>
  )

  const initialCircle = (
    <SVGWrapper>
      <svg width="3em" height="3em">
        <circle cx="1.5em" cy="1.5em" r="1.25em" strokeWidth="0.25em" stroke={borderColor} fill={randomHashColor()} />
        {!isExpired && !isRemoved && circleText}
      </svg>
    </SVGWrapper>
  )

  const imageCircle = (
    <AvatarImageBorder borderColor={borderColor}>
      <AvatarImage {...user} />
    </AvatarImageBorder>
  )

  const firstName = head(words(primaryLine))
  const titleMsg = (
    <TitlebarText>
      <Trans i18nKey="titleSalutation">
        Hi, <strong>{{ firstName }}</strong>
      </Trans>
    </TitlebarText>
  )

  const postscript = isRemoved ? (
    <ExpiredRemovedMsg>{t('removedUserMsg')}</ExpiredRemovedMsg>
  ) : isExpired ? (
    <ExpiredRemovedMsg>{t('expiredUserMsg')}</ExpiredRemovedMsg>
  ) : isInvite ? (
    <InviteMsg>{t('inviteUserMsg')}</InviteMsg>
  ) : null

  const textContent = (
    <AvatarText>
      <TopText>
        {isTitle ? titleMsg : primaryLine}
        {postscript}
      </TopText>
      {secondaryLine != null && !isTitle && <BottomText>{secondaryLine}</BottomText>}
    </AvatarText>
  )

  return (
    <AvatarContainer isTitle={isTitle} isFormer={isExpired || isRemoved} isSmall={isSmall}>
      <CircleWrapper isCircleSmall={isCircleSmall}>
        {!isInvite && startsWith(user.picture, 'http') ? imageCircle : initialCircle}
      </CircleWrapper>
      {isImageOnly ? null : textContent}
    </AvatarContainer>
  )
}

export default Avatar
