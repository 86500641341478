/*
 *
 * PoweredBy Component
 *
 */

import React from 'react'
import styled from 'styled-components'
import { text } from 'utils/colors'
import { useTranslation } from 'react-i18next'

import ComplyifyLogo from 'components/ComplyifyLogo'

export const PoweredByContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  color: ${text.nav};
  width: 85%;
  margin: 0 auto;
  height: 1.5em;

  p {
    font-weight: 300;
    margin: 0;
    text-align: right;
    padding-right: 0.5em;
    font-size: 0.75em;
  }

  svg {
    width: 9rem;
  }
`

const PoweredBy: React.FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <PoweredByContainer>
      <p>{t('poweredBy')}</p>
      <ComplyifyLogo />
    </PoweredByContainer>
  )
}

export default PoweredBy
