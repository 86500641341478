const mockDashboardData = [
  {
    name: '2020 Q1',
    totalReportsSent: 123,
    dueByDates: ['2020-04-18T22:02:05.343Z'],
    questionStatistics: { COMPLIANT: 354, NON_COMPLIANT: 719, NOT_APPLICABLE: 836 },
    templates: [{ id: '5e719d503aebf3bb5264d32a', name: 'Trive Default', numCompanies: 123 }],
    reportStatusCounts: {
      COMPLIANT: 29,
      NON_COMPLIANT: 21,
      PENDING: 37,
      OVERDUE: 36,
    },
  },
  {
    name: '2019 Q4',
    totalReportsSent: 106,
    dueByDates: ['2020-04-16T22:02:05.343Z'],
    questionStatistics: { COMPLIANT: 673, NON_COMPLIANT: 499, NOT_APPLICABLE: 860 },
    templates: [{ id: '5e719d503aebf3bb5264d32a', name: 'Trive Default', numCompanies: 166 }],
    reportStatusCounts: {
      COMPLIANT: 59,
      NON_COMPLIANT: 35,
      PENDING: 0,
      OVERDUE: 12,
    },
  },
  {
    name: '2019 Q3',
    totalReportsSent: 79,
    dueByDates: ['2020-04-18T22:02:05.344Z'],
    questionStatistics: { COMPLIANT: 600, NON_COMPLIANT: 313, NOT_APPLICABLE: 601 },
    templates: [{ id: '5e719d503aebf3bb5264d32a', name: 'Trive Default', numCompanies: 177 }],
    reportStatusCounts: {
      COMPLIANT: 54,
      NON_COMPLIANT: 22,
      PENDING: 0,
      OVERDUE: 3,
    },
  },
]

export default mockDashboardData
