import React from 'react'
import { useAuth0 } from 'utils/auth0'
import { getHost } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'
import ErrorView from 'components/ErrorView'
import { findChangePasswordUrl, update, CLOSE_USER_UPDATE_MODAL } from 'actions/userActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Form from 'components/Form/GoodForm'

import { ModalTitle } from 'components/styles'
import { SettingsModalContainer, SettingsBtns, CloseIcon, FormSuccessMsg } from './styles'

interface SettingsModalProps {
  isOpen: boolean
  onClose: () => void
}

const SettingsModal: React.FC<SettingsModalProps> = ({ isOpen, onClose }: SettingsModalProps) => {
  const { t } = useTranslation()
  const auth0Context = useAuth0()
  const { changePasswordUrl, user } = useSelector((state: any) => state.user)
  const dispatch = useDispatch()

  const handleLogout = (): void => {
    sessionStorage.clear()
    auth0Context.logout({
      returnTo: getHost(),
    })
  }

  const handleClose = () => {
    dispatch({ type: CLOSE_USER_UPDATE_MODAL })
    onClose()
  }

  const handleSubmitUpdateUser = (data: any): void => {
    const { name, email } = data
    dispatch(update(auth0Context, { name, email }))
  }

  const handleChangePassword = (): void => {
    dispatch(findChangePasswordUrl(auth0Context))
  }

  let ModalContent
  if (changePasswordUrl.error) {
    ModalContent = <ErrorView errorMsg={t('changePasswordUrlErrorMsg')} />
  } else if (changePasswordUrl.isFetching) {
    ModalContent = <Spinner />
  } else if (changePasswordUrl.item.url !== '') {
    ModalContent = <Spinner />
    window.location.replace(changePasswordUrl.item.url)
  } else {
    ModalContent = (
      <>
        <ModalTitle>
          {t('userSettingsTitle')}
          <CloseIcon onClick={handleClose}>
            <FontAwesomeIcon icon="times" />
          </CloseIcon>
        </ModalTitle>

        <SettingsModalContainer>
          {user.isFetching ? (
            <Spinner />
          ) : user.isUpdateSuccessful ? (
            <FormSuccessMsg>{t('userUpdateSuccessMsg')}</FormSuccessMsg>
          ) : (
            <Form
              onSubmit={handleSubmitUpdateUser}
              isHideShowBtns
              fields={[
                {
                  type: 'text',
                  name: 'name',
                  initialValue: auth0Context?.user?.name || '',
                  ref: { required: true, minLength: 1 },
                  errors: { required: t('nameFieldRequired'), min: t('nameFieldRequired') },
                },
                {
                  type: 'text',
                  name: 'email',
                  initialValue: auth0Context?.user?.email || '',
                  ref: { required: true, minLength: 1 },
                  errors: { required: t('emailFieldRequired'), min: t('emailFieldRequired') },
                },
              ]}
            />
          )}

          <SettingsBtns>
            <Button onClick={handleChangePassword} color="success">
              {t('changePassword')}
            </Button>
            <Button onClick={handleLogout} color="error">
              {t('logoutBtn')}
            </Button>
          </SettingsBtns>
        </SettingsModalContainer>
      </>
    )
  }

  return <Modal isOpen={isOpen} content={ModalContent} onClose={handleClose} width="lg" />
}

export default SettingsModal
