import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from 'components/Avatar'
import { TitlebarContainer, TitleIcon, AvatarWrapper } from './styles'
import { useAuth0 } from '../utils/auth0'

interface TitlebarProps {
  toggleModal: () => void
}

const Titlebar: React.FC<TitlebarProps> = ({ toggleModal }: { toggleModal: Function }) => {
  const { user } = useAuth0()

  const handleOpenUserSettingsModal = () => {
    console.log('handleOpenUserSettingsModal()')
    toggleModal()
  }

  return (
    <TitlebarContainer>
      <AvatarWrapper onClick={handleOpenUserSettingsModal}>
        <Avatar user={{ ...user }} isTitle />
      </AvatarWrapper>
      <TitleIcon onClick={handleOpenUserSettingsModal}>
        <FontAwesomeIcon icon="cog" />
      </TitleIcon>
      <TitleIcon className="triggerChat">
        <FontAwesomeIcon icon="question-circle" />
      </TitleIcon>
    </TitlebarContainer>
  )
}

export default Titlebar
