export const bgOpacity = 0.08
export const hintOpacity = 0.05
export const activeMultiplier = 0.8
export const fadedOpacity = 0.75
export const invertedOpacity = 0.6

export const logo = {
  hex: '#39586F',
  rgb: '57,88,111',
}
export const dark = {
  hex: '#1B2B36',
  rgb: '27,43,54',
}
export const blue = {
  hex: '#4D8CBD',
  rgb: '77,140,189',
}
export const green = {
  hex: '#28BD59',
  rgb: '40,189,89',
}
export const red = {
  hex: '#F0433E',
  rgb: '240,67,62',
}
export const yellow = {
  hex: '#F8AF41',
  rgb: '248,175,65',
}
export const white = {
  hex: '#FFF',
  rgb: '255,255,255',
}
export const black = {
  hex: '#333',
  rgb: '33,33,33',
}
export const darkGrey = {
  hex: '#555',
  rgb: '55,55,55',
}
export const mediumGrey = {
  hex: '#999',
  rgb: '99,99,99',
}
export const grey = {
  hex: '#aaa',
  rgb: '170,170,170',
}
export const lightGrey = {
  hex: '#ddd',
  rgb: '221,221,221',
}
export const offWhite = {
  hex: '#f9f9fc',
  rgb: '249,249,252',
}

export const lighten = `rgba(${white.rgb},0.1)`
export const darken = `rgba(${black.rgb},0.5)`

export const text = {
  logo: logo.hex,
  main: black.hex,
  contentBox: black.hex,
  contrast: white.hex,
  nav: white.hex,
  titlebar: white.hex,
  reportUser: white.hex,
  active: blue.hex,
  success: green.hex,
  error: red.hex,
  warning: yellow.hex,
  secondary: darkGrey.hex,
  COMPLETE: green.hex,
  COMPLIANT: green.hex,
  NON_COMPLIANT: red.hex,
  NOT_APPLICABLE: mediumGrey.hex,
  OVERDUE: yellow.hex,
  PENDING: blue.hex,
  INACTIVE: grey.hex,
  inactive: grey.hex,
  light: grey.hex,
}

export const bg = {
  logo: `rgba(${logo.rgb},${bgOpacity})`,
  main: offWhite.hex,
  contentBox: white.hex,
  contrast: dark.hex,
  nav: dark.hex,
  titlebar: logo.hex,
  reportUser: logo.hex,
  active: `rgba(${blue.rgb},${bgOpacity})`,
  success: `rgba(${green.rgb},${bgOpacity})`,
  error: `rgba(${red.rgb},${bgOpacity})`,
  warning: `rgba(${yellow.rgb},${bgOpacity})`,
  secondary: `rgba(${darkGrey.rgb},${bgOpacity})`,
  COMPLETE: `rgba(${green.rgb},${bgOpacity})`,
  COMPLIANT: `rgba(${green.rgb},${bgOpacity})`,
  NON_COMPLIANT: `rgba(${red.rgb},${bgOpacity})`,
  NOT_APPLICABLE: `rgba(${mediumGrey.rgb},${bgOpacity})`,
  OVERDUE: `rgba(${yellow.rgb},${bgOpacity})`,
  PENDING: `rgba(${blue.rgb},${bgOpacity})`,
  INACTIVE: `rgba(${grey.rgb},${bgOpacity})`,
  inactive: `rgba(${grey.rgb},${bgOpacity})`,
  light: white.hex,
  logoHint: `rgba(${logo.rgb},${hintOpacity})`,
  hint: `rgba(${blue.rgb},${hintOpacity})`,
}

export const bgInverted = {
  logo: `rgba(${logo.rgb},${invertedOpacity})`,
  active: `rgba(${blue.rgb},${invertedOpacity})`,
  success: `rgba(${green.rgb},${invertedOpacity})`,
  error: `rgba(${red.rgb},${invertedOpacity})`,
  warning: `rgba(${yellow.rgb},${invertedOpacity})`,
  secondary: `rgba(${darkGrey.rgb},${invertedOpacity})`,
  disabled: `rgba(${mediumGrey.rgb},${invertedOpacity})`,
  COMPLETE: `rgba(${green.rgb},${invertedOpacity})`,
  COMPLIANT: `rgba(${green.rgb},${invertedOpacity})`,
  NON_COMPLIANT: `rgba(${red.rgb},${invertedOpacity})`,
  NOT_APPLICABLE: `rgba(${mediumGrey.rgb},${invertedOpacity})`,
  OVERDUE: `rgba(${yellow.rgb},${invertedOpacity})`,
  PENDING: `rgba(${blue.rgb},${invertedOpacity})`,
  INACTIVE: `rgba(${grey.rgb},${invertedOpacity})`,
  inactive: `rgba(${grey.rgb},${invertedOpacity})`,
}

export const bgOpaque = {
  logo: '#E6E9ED',
  active: '#EEF4F9',
  main: offWhite.hex,
  contentBox: white.hex,
  contrast: dark.hex,
  nav: dark.hex,
  titlebar: logo.hex,
  light: white.hex,
}
export const colors = {
  logo: logo.hex,
  lighten,
  darken,
  text,
  bg,
  dark: dark.hex,
  blue: blue.hex,
  green: green.hex,
  red: red.hex,
  yellow: yellow.hex,
  white: white.hex,
  black: black.hex,
  darkGrey: darkGrey.hex,
  grey: grey.hex,
  lightGrey: lightGrey.hex,
  offWhite: offWhite.hex,

  shadow: lightGrey.hex,
  border: lightGrey.hex,
}

export default colors
