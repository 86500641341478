import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import initIcons from 'utils/icons'
import history from 'utils/history'
import { getHost } from 'api/helpers'
import { Auth0Provider } from 'utils/auth0'

import App from 'app/App'
import authConfig from './auth_config.json'
import rootReducer from './reducers'
import './i18n'
import './reset.css'

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
}

const middleware = compose(applyMiddleware(thunk.withExtraArgument({ host: getHost() })))
if (window.__REDUX_DEVTOOLS_EXTENSION__) compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__())

const store = createStore(rootReducer, middleware)

const render = () => {
  ReactDOM.render(
    <Auth0Provider
      domain={authConfig.domain}
      client_id={authConfig.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={authConfig.audience}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>,
    document.getElementById('root'),
  )
}

initIcons()
render()

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('app/App', render)
// }
