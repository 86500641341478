import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from 'utils/auth0'
import { map, isEmpty, orderBy } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { listTemplateOptions } from 'actions/templateActions'
import { listCompanies, addCompany, removeCompany } from 'actions/companyActions'
import { findClient } from 'actions/clientActions'

import { Company } from 'models/company'

import EmptyView from 'components/EmptyView'
import ErrorView from 'components/ErrorView'
import Button from 'components/Button'
import HorizontalRule from 'components/HorizontalRule'
import Avatar from 'components/Avatar'
import Spinner from 'components/Spinner'
import LatestEvent from 'components/LatestEvent'
import StatusBadge from 'components/StatusBadge'
import Form from 'components/Form/GoodForm'

import { PageContent, PageTitle, ContentBoxLists, FormWrapper, ColumnHeading } from 'components/styles'
import {
  CompanyTable,
  CompanyIcon,
  CompanyName,
  CompanyStatus,
  CompanyReport,
  UsersWrapper,
  CompanyLatest,
  CompanyHeader,
  CompanyItem,
} from './styles'

const PortfolioPage: React.FC<{}> = () => {
  const { t } = useTranslation()
  const [isNewFormShown, setIsNewFormShown] = React.useState<boolean>(false)

  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const history = useHistory()

  const { companyList } = useSelector((state: any) => state.companies)
  const { templateOptions } = useSelector((state: any) => state.templates)
  const { client } = useSelector((state: any) => state.client)

  React.useEffect(() => {
    if (!companyList.lastUpdated) dispatch(listCompanies(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setIsNewFormShown(!!companyList.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!templateOptions.lastUpdated) dispatch(listTemplateOptions(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    dispatch(findClient(auth0Context))
  }, [dispatch, auth0Context])

  const handleAddCompany = ({ name, templateId }: { name: string; templateId: string }): void => {
    console.log('handleAddCompany', { name, templateId })
    dispatch(addCompany(auth0Context, { name, templateId, daysToComplete: 90, users: [] }))
  }

  const handleRemoveCompany = (event: any, companyId: string): void => {
    console.log('handleRemoveCompany', companyId)
    event.stopPropagation()
    dispatch(removeCompany(auth0Context, companyId))
  }

  const handleGoToDetail = (companyId: string) => {
    history.push(`/company/${companyId}`)
  }

  const sortedCompanies = orderBy(companyList.items, 'status', 'desc')
  console.log('companyList.items', companyList.items)
  let content
  if (companyList.error) {
    content = <ErrorView errorMsg={t('listCompaniesError')} serverResponse={t(companyList.error)} />
  } else if (companyList.isFetching) {
    content = <Spinner />
  } else if (isEmpty(sortedCompanies)) {
    content = <EmptyView emptyMsg={t('noCompaniesMsg')} />
  } else {
    content = (
      <CompanyTable>
        <CompanyHeader>
          <div />
          <ColumnHeading isLeftAligned>{t('companyNameCol')}</ColumnHeading>
          <ColumnHeading>{t('companyStatusCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('companyUsersCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('companyReportCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('companyLatestCol')}</ColumnHeading>
          <ColumnHeading isDoubleCol>
            {!isNewFormShown && (
              <Button onClick={() => setIsNewFormShown(true)} size="small" color="success">
                {t('addCompanyBtn')}
              </Button>
            )}
          </ColumnHeading>
        </CompanyHeader>
        <HorizontalRule isPadded />

        {map(sortedCompanies, (co: Company) => {
          const isCompanyDisabled = co.status === 'DISABLED'
          return (
            <React.Fragment key={`company=${co.id}`}>
              <CompanyItem onClick={() => handleGoToDetail(co.id)}>
                <CompanyIcon>
                  <FontAwesomeIcon icon="building" />
                </CompanyIcon>
                <CompanyName isDisabled={isCompanyDisabled}>{co.name}</CompanyName>
                <CompanyStatus>
                  {isCompanyDisabled ? 'REMOVED' : <StatusBadge status={co.reportStatus} />}
                </CompanyStatus>
                <UsersWrapper>
                  {isCompanyDisabled
                    ? null
                    : map(co.users, u => (
                        <Avatar
                          key={`contact-${co.id}-${u.id}`}
                          user={u}
                          status={u?.reportStatus}
                          isImageOnly
                          isCircleSmall
                        />
                      ))}
                </UsersWrapper>
                <CompanyReport>{isCompanyDisabled ? null : co.template?.name}</CompanyReport>
                <CompanyLatest>{isCompanyDisabled ? null : <LatestEvent events={co.events} />}</CompanyLatest>
                <Button to={`/company/${co.id}`} size="small" color="secondary">
                  {isCompanyDisabled ? t('viewBtn') : t('editBtn')}
                </Button>
                {co.status === 'ENABLED' ? (
                  <Button onClick={e => handleRemoveCompany(e, co.id)} isSimple color="error">
                    <FontAwesomeIcon icon="trash-alt" />
                  </Button>
                ) : (
                  <div />
                )}
              </CompanyItem>
              <HorizontalRule lineStyle="dashed" isHideLast />
            </React.Fragment>
          )
        })}
      </CompanyTable>
    )
  }

  return (
    <PageContent>
      <PageTitle>{t('portfolioTitle')}</PageTitle>
      <ContentBoxLists>
        <FormWrapper isOpen={!!isNewFormShown}>
          <Form
            onSubmit={handleAddCompany}
            onCancel={() => setIsNewFormShown(false)}
            errorMsg={t(companyList.error)}
            fields={[
              {
                type: 'text',
                name: 'name',
                initialValue: '',
                ref: { required: true, minLength: 1 },
                errors: { required: t('companyNameRequired'), min: t('companyNameRequired') },
              },
              {
                type: 'select',
                name: 'templateId',
                options: templateOptions?.items,
                initialValue: client.items?.defaultTemplateId || '',
                ref: { required: true },
                errors: { required: t('templateFieldRequired') },
              },
            ]}
          />
        </FormWrapper>
        {content}
        <HorizontalRule lineStyle="dashed" isHideLast />
      </ContentBoxLists>
    </PageContent>
  )
}

export default PortfolioPage
