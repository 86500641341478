import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { Auth0Context } from 'utils/auth0'

const ctx = 'api/report'

export const LIST_REPORTS_LOADING = `${ctx}/LIST_REPORTS_LOADING`
export const LIST_REPORTS_SUCCESS = `${ctx}/LIST_REPORTS_SUCCESS`
export const LIST_REPORTS_FAILURE = `${ctx}/LIST_REPORTS_FAILURE`

export const FIND_REPORT_LOADING = `${ctx}/FIND_REPORT_LOADING`
export const FIND_REPORT_SUCCESS = `${ctx}/FIND_REPORT_SUCCESS`
export const FIND_REPORT_FAILURE = `${ctx}/FIND_REPORT_FAILURE`

export const listReports = (
  auth0Context: Auth0Context,
  companyId: string,
  params?: { page?: number; perPage?: number; includeTotal?: boolean },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: LIST_REPORTS_LOADING })

  let url = `${host}/v1/report?companyId=${companyId}`
  if (params?.page) url += `&page=${params?.page}`
  if (params?.perPage) url += `&perPage=${params?.perPage}`
  if (params?.includeTotal) url += `&includeTotal=${params?.includeTotal}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_REPORTS_SUCCESS,
        payload: {
          page: data.page,
          perPage: data.perPage,
          totalItems: data.totalItems,
          items: data.items,
        },
      }),
    )
    .catch(err => dispatch({ type: LIST_REPORTS_FAILURE, payload: { errorMsg: err.message } }))
}

export const findReport = (auth0Context: Auth0Context, reportId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: FIND_REPORT_LOADING })

  const url = `${host}/v1/report/${reportId}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: FIND_REPORT_SUCCESS,
        payload: {
          report: data.report,
        },
      }),
    )
    .catch(err => dispatch({ type: FIND_REPORT_FAILURE, payload: { errorMsg: err.message } }))
}
