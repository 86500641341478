import React from 'react'
import styled from 'styled-components'
import { useStore } from 'react-redux'
import { text, colors } from 'utils/colors'
import { omit } from 'lodash'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TriveLogo from 'components/TriveLogo'
import ComplyifyLogo from 'components/ComplyifyLogo'
import Button from 'components/Button'
import Signature from 'components/Signature'
import Example from 'components/Form/Example'
import Avatar from 'components/Avatar'
import { PageContent, PageTitle } from 'components/styles'

import { useAuth0 } from '../../utils/auth0'

const TempComponentsWrapper = styled.div`
  & > div {
    margin: 1rem auto;
    border-top: 1px solid ${colors.lightGrey};
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
  }
`
const LogoWrapper = styled.div`
  width: 20rem;
  margin: 0 auto;
`

const Items = styled.div`
  display: flex;
  justify-content: space-around;
  & > * {
    margin: 0 1rem;
  }
`

const ComponentLibrary: React.FC<{}> = () => {
  const { user } = useAuth0()
  const { t } = useTranslation()
  const store = useStore()
  console.log('STORE:', store.getState())
  console.log('USER:', user)

  const handleClick = (): void => {
    console.log('handleClick()')
  }

  const handleLogout = (): void => {
    console.log('handleLogout')
  }

  return (
    <PageContent>
      <PageTitle>{t('componentLibraryTitle')}</PageTitle>
      <TempComponentsWrapper>
        <TriveLogo color={text.logo} />,
        <LogoWrapper>
          <ComplyifyLogo color={text.logo} />
        </LogoWrapper>
        ,
        <Signature name={'Peter Gibbons'} />,
        <Items>
          <Button onClick={handleClick} color="success">
            {t('addTemplateBtn')}
          </Button>
          <Button onClick={handleClick} color="active">
            <React.Fragment>
              <FontAwesomeIcon icon="plus" />
              {t('addSectionBtn')}
            </React.Fragment>
          </Button>
          <Button onClick={handleLogout} color="error">
            {t('logoutBtn')}
          </Button>
        </Items>
        ,
        <Example />,
        <Items>
          <Avatar user={{ ...user }} secondaryText={user?.email} />
          <Avatar user={omit(user, 'name')} />
          <Avatar user={{ ...user }} isImageOnly />
          <Avatar user={{ ...user }} isImageOnly status="COMPLIANT" />
          <Avatar user={{ ...user }} isImageOnly status="OVERDUE" />
        </Items>
      </TempComponentsWrapper>
    </PageContent>
  )
}

export default ComponentLibrary
