import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { isEmpty, map, filter, includes } from 'lodash'
import { AdminReport, ReportUser } from 'models/report'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { listReports } from 'actions/reportActions'

import ErrorView from 'components/ErrorView'
import EmptyView from 'components/EmptyView'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import LatestEvent from 'components/LatestEvent'
import StatusBadge from 'components/StatusBadge'
import Avatar from 'components/Avatar'

import { ContentBox, ColumnHeading, ToggleIcon } from 'components/styles'
import {
  ReportsTable,
  ReportRow,
  ReportRowHeader,
  ReportRowMain,
  ReportName,
  ReportTemplate,
  ReportStatus,
  UsersWrapper,
  ReportResponses,
  ReportResponse,
  HiddenReportDetail,
  ReportUserRow,
  UserDetailStatus,
  UserDetailNotSubmitted,
  UserDetailTimestamp,
  UserDetailResponse,
} from './styles'

interface ReportListProps {
  companyId: string
}

dayjs.extend(relativeTime)

const ReportsList: React.FC<ReportListProps> = ({ companyId }: ReportListProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const [openReport, setOpenReport] = React.useState<number | undefined>(0)

  const { reportList } = useSelector((state: any) => state.reports)

  React.useEffect(() => {
    dispatch(listReports(auth0Context, companyId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggleReport = (reportIndex: number) => {
    if (reportIndex === openReport) {
      setOpenReport(undefined)
    } else {
      setOpenReport(reportIndex)
    }
  }

  const handleGoToReport = (reportId: string) => {
    console.log('handleGoToReport', reportId)
  }

  const renderReport = (report: AdminReport, index: number) => {
    const completeUsers = filter(report.users, (u: ReportUser) =>
      includes(['COMPLIANT', 'NON_COMPLIANT'], u.reportStatus),
    )
    const pendingUsers = filter(report.users, (u: ReportUser) => includes(['PENDING', 'OVERDUE'], u.reportStatus))
    return (
      <ReportRow key={report.id} isActive={openReport === index}>
        <ReportRowMain onClick={() => handleToggleReport(index)}>
          <ToggleIcon isOpen={openReport === index}>
            <FontAwesomeIcon icon="angle-right" />
          </ToggleIcon>
          <ReportName>{report.name}</ReportName>
          <ReportTemplate>{report.template.name}</ReportTemplate>
          <ReportStatus>
            <StatusBadge status={report.status} />
          </ReportStatus>
          <UsersWrapper>
            {map(completeUsers, (u: ReportUser) => (
              <Avatar
                key={`completedUser-${companyId}-${u.id}`}
                user={u}
                status={u?.reportStatus}
                isImageOnly
                isCircleSmall
              />
            ))}
          </UsersWrapper>
          <UsersWrapper>
            {map(pendingUsers, (u: ReportUser) => (
              <Avatar
                key={`pendingUser-${companyId}-${u.id}`}
                user={u}
                status={u?.reportStatus}
                isImageOnly
                isCircleSmall
              />
            ))}
          </UsersWrapper>
          <ReportResponses>
            <ReportResponse status="NON_COMPLIANT">
              {t('nonCompliantCount', { count: report.questionStatistics.NON_COMPLIANT })}
            </ReportResponse>
            <ReportResponse status="NOT_APPLICABLE">
              {t('notApplicableCount', { count: report.questionStatistics.NOT_APPLICABLE })}
            </ReportResponse>
          </ReportResponses>
          <LatestEvent events={report.events} />
          <Button to={`/report/${report.id}`} color="active" size="small">
            {t('findReportBtn')}
          </Button>
        </ReportRowMain>

        <HiddenReportDetail isOpen={openReport === index}>
          {map(report.users, u => {
            const isUserReportComplete = includes(['COMPLIANT', 'NON_COMPLIANT'], u.reportStatus)
            const userReportStatus = isUserReportComplete ? 'COMPLETE' : u.reportStatus
            const userResponses = isUserReportComplete ? (
              <>
                <ReportResponse status="NON_COMPLIANT">
                  {t('nonCompliantCount', { count: u.questionStatistics.NON_COMPLIANT })}
                </ReportResponse>
                <ReportResponse status="NOT_APPLICABLE">
                  {t('notApplicableCount', { count: u.questionStatistics.NOT_APPLICABLE })}
                </ReportResponse>
              </>
            ) : (
              <UserDetailNotSubmitted>{t('notSubmittedReportMsg')}</UserDetailNotSubmitted>
            )
            const completedWhen = dayjs(report.dueBy).fromNow() // TODO not the correct value - need completed date
            const timeMsg = isUserReportComplete
              ? t('completedReportMsg', { completedWhen })
              : u.reportStatus === 'OVERDUE'
              ? t('overdueReportMsg', { fromNow: dayjs(report.dueBy).fromNow() })
              : t('pendingReportMsg', { toNow: dayjs(report.dueBy).toNow(true) })
            return (
              <ReportUserRow key={`userDetail-${companyId}-${u.id}`} onClick={() => handleGoToReport(report.id)}>
                <Avatar user={u} isCircleSmall />
                <UserDetailStatus status={userReportStatus}>{t(userReportStatus)}</UserDetailStatus>
                <UserDetailTimestamp isComplete={isUserReportComplete} status={u.reportStatus}>
                  {timeMsg}
                </UserDetailTimestamp>
                <UserDetailResponse>{userResponses}</UserDetailResponse>
              </ReportUserRow>
            )
          })}
        </HiddenReportDetail>
      </ReportRow>
    )
  }

  let content
  if (reportList.error) {
    content = <ErrorView errorMsg={t('noReportsToListMsg')} serverResponse={t(reportList.error)} />
  } else if (reportList.isFetching) {
    content = <Spinner />
  } else if (isEmpty(reportList.items)) {
    content = <EmptyView emptyMsg={t('noReportsToListMsg')} />
  } else {
    content = (
      <ReportsTable>
        <ReportRowHeader>
          <ColumnHeading isLeftAligned size="lg" isDoubleCol>
            {t('reportsTableTitle')}
          </ColumnHeading>
          <ColumnHeading isLeftAligned>{t('reportNameCol')}</ColumnHeading>
          <ColumnHeading>{t('reportStatusCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('reportCompletedCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('reportPendingCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned>{t('reportResponsesCol')}</ColumnHeading>
          <ColumnHeading isLeftAligned isDoubleCol>
            {t('reportLatestCol')}
          </ColumnHeading>
        </ReportRowHeader>
        {map(reportList?.items, (r: AdminReport, index: number) => renderReport(r, index))}
      </ReportsTable>
    )
  }

  return <ContentBox>{content}</ContentBox>
}

export default ReportsList
