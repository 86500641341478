export const getHost = (loc = window.location.href): string => {
  if (/babyyoda.comply.cloud/.test(loc)) {
    return 'https://babyyoda-staging.comply.cloud'
  }

  return 'http://localhost:3000'
}

export const getClientId = (): string => {
  const appMetadata = JSON.parse(sessionStorage.getItem('app_metadata') as string) || {}
  return appMetadata.clientId
}

export const getCompanyId = (): string => {
  const appMetadata = JSON.parse(sessionStorage.getItem('app_metadata') as string) || {}
  return appMetadata.companyId
}
