import React from 'react'
import { map, some, forEach, isEmpty, toString } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button'
import ErrorView from 'components/ErrorView'
import { ActionBtns } from 'components/styles'
import styled from 'styled-components'
import { colors, text } from 'utils/colors'

interface FormProps {
  fields: Field[]
  onSubmit: (data: any) => void
  onCancel?: () => void
  actionBtns?: any
  errorMsg?: string
  isHideShowBtns?: boolean
  submitBtn?: {
    label: string
    size?: 'small' | 'large'
    color?: 'logo' | 'active' | 'success' | 'error' | 'warning' | 'secondary'
  }
  cancelBtn?: {
    label: string
    onCancel?: any
    size?: 'small' | 'large'
    color?: 'logo' | 'active' | 'success' | 'error' | 'warning' | 'secondary'
  }
}

interface Field {
  name: string
  type: 'text' | 'email' | 'number' | 'select'
  ref: {
    required?: boolean
    min?: number
    max?: number
    minLength?: number
    maxLength?: number
    pattern?: RegExp
    validate?: any
  }
  errors: {
    required?: string
    min?: string
    max?: string
    minLength?: string
    maxLength?: string
    pattern?: string
    validate?: string
  }
  initialValue?: string
  options?: { value: string; label: string }[]
}

const FormContainer = styled.form<{ isFlex?: boolean }>`
  display: ${p => (p.isFlex ? 'flex' : 'block')};
  align-items: center;
  max-width: 25rem;
  margin: 0 auto;
  & > * {
    margin-bottom: 1rem;
  }
  * {
    margin-right: ${p => (p.isFlex ? '0.5rem' : '')};
  }
`

const Label = styled.label`
  display: block;
  font-size: 0.8rem;
  width: 100%;
`

const SelectInput = styled.select<{ hasError?: boolean }>`
  display: block;
  font-size: 0.9rem;
  margin-top: 0.1rem;
  padding: 0.5rem 3%;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid ${p => (p.hasError ? text.error : colors.border)};
  appearance: none;
  background-color: #fff;
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 2rem auto, 100%;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
`

const SelectOption = styled.option`
  appearance: none;
`

const InputField = styled.input<{ hasError?: boolean }>`
  display: block;
  font-size: 0.9rem;
  margin-top: 0.1rem;
  padding: 0.5rem 3%;
  width: 94%;
  border-radius: 0.25rem;
  border: 1px solid ${p => (p.hasError ? text.error : colors.border)};
`

const ErrorMsg = styled.div`
  color: ${text.error};
  margin-top: 0.1rem;
  height: 1rem;
  font-size: 0.8rem;
`

const Form: React.FC<FormProps> = ({ fields, errorMsg, isHideShowBtns, onSubmit, onCancel }: FormProps) => {
  const { t } = useTranslation()
  const [isTouched, setIsTouched] = React.useState<boolean>(false)
  const { handleSubmit, errors, register, setValue, triggerValidation, watch, clearError } = useForm({})

  const watchedFields = watch()
  const newTouchedValue =
    !isEmpty(watchedFields) &&
    some(
      fields,
      (field: Field) =>
        !isEmpty(watchedFields[field.name]) && toString(field.initialValue) !== toString(watchedFields[field.name]),
    )

  if (!isTouched && newTouchedValue) {
    setIsTouched(newTouchedValue)
  }

  const handleSubmitInternally = (data: any) => {
    setIsTouched(false)
    clearError()
    onSubmit(data)
    if (onCancel) return onCancel()
  }

  const handleCancel = () => {
    forEach(fields, (field: Field) => {
      setValue(field.name, field.initialValue)
    })
    setIsTouched(false)
    clearError()
    if (onCancel) return onCancel()
  }

  return (
    <FormContainer onSubmit={handleSubmit(handleSubmitInternally)}>
      {map(fields, (field: Field) => (
        <Label key={field.name} htmlFor={field.name}>
          {t(field.name)}
          {field.type === 'select' ? (
            <SelectInput
              name={field.name}
              ref={register(field.ref)}
              defaultValue={field.initialValue}
              onBlur={() => triggerValidation(field.name)}
            >
              {map(field.options, ({ value, label }) => (
                <SelectOption key={`option-${value}`} value={value} selected={field.initialValue === value}>
                  {label}
                </SelectOption>
              ))}
            </SelectInput>
          ) : (
            <InputField
              name={field.name}
              type={field.type}
              defaultValue={field.initialValue}
              ref={register(field.ref)}
              onBlur={() => triggerValidation(field.name)}
            />
          )}

          {errors[field.name] && (
            <ErrorMsg>
              {field.errors[
                errors[field.name].type as
                  | 'required'
                  | 'min'
                  | 'max'
                  | 'minLength'
                  | 'maxLength'
                  | 'pattern'
                  | 'validate'
              ] || t(`${field.name}_${errors[field.name].type}`)}
            </ErrorMsg>
          )}
        </Label>
      ))}
      <ActionBtns isHidden={isHideShowBtns && !isTouched}>
        <Button type="submit" size="small" color="success">
          {t('submitBtn')}
        </Button>
        <Button onClick={handleCancel} size="small" color="secondary">
          {t('cancelBtn')}
        </Button>
        {errorMsg && <ErrorView serverResponse={errorMsg} hideContactMsg />}
      </ActionBtns>
    </FormContainer>
  )
}

export default Form
