import styled, { css } from 'styled-components'
import { text, bg, colors } from 'utils/colors'
import { ContentBox } from 'components/styles'

// PortfolioPage

export const CompanyTable = styled.div`
  display: grid;
  grid-template-columns: 1.5rem 1fr 10rem 10rem 1fr 2fr 5rem 2rem;
  grid-gap: 0.5rem;
  align-items: center;
  padding: 1rem 0;
`
export const companyRow = css`
  grid-column: 1 / -1;
  display: inherit;
  grid-template-columns: inherit;
  grid-gap: inherit;
  align-items: center;
  padding: 0 1rem;
`
export const CompanyHeader = styled.div`
  ${companyRow}
`
export const CompanyItem = styled.div`
  ${companyRow}
  cursor: pointer;
  user-select: none;
`
export const CompanyIcon = styled.div`
  font-size: 1.5rem;
  color: ${text.logo};
`
const companyListItems = css`
  font-size: 0.9rem;
`
export const CompanyName = styled.div<{ isDisabled: boolean }>`
  ${companyListItems}
  font-weight: 600;
  color: ${p => (p.isDisabled ? text.inactive : 'inherit')};
`
export const CompanyStatus = styled.div`
  ${companyListItems}
  justify-self: center;
  color: ${text.inactive};
`
export const CompanyReport = styled.div`
  ${companyListItems}
`
export const UsersWrapper = styled.div`
  ${companyListItems}
  display: flex;
  padding-left: 0.75rem;
  & > * {
    margin-left: -0.75rem;
  }
`
export const CompanyLatest = styled.div`
  ${companyListItems}
`

// CompanyView

export const CompanyDetailContent = styled.div`
  display: grid;
  grid-template-columns: auto 15rem;
  grid-gap: 1rem;
`
export const CompanySettingsWrapper = styled.div`
  width: 90%;
  margin: 0 auto 1rem;
  & > * {
    margin: 0 0 1rem;
  }
`

export const ReportSettingsContainer = styled(ContentBox)``

export const UsersTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  align-items: center;
  padding: 1rem;
`
export const UserEmail = styled.div`
  font-weight: 200;
  font-size: 0.9rem;
`
export const LastUserCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const UserStatusWrapper = styled.div`
  line-height: 1.25rem;
  font-size: 0.9rem;
`
export const UserStatus = styled.div<{ status: 'COMPLETE' | 'OVERDUE' | 'PENDING' | 'INACTIVE' }>`
  font-weight: 600;
  color: ${p => text[p.status]};
`
export const UserRemovedStatus = styled.div`
  opacity: 0.6;
  color: ${text.inactive};
`
export const UserButton = styled.div`
  justify-self: end;
`
export const ReportsListWrapper = styled.div`
  grid-column: 1 / -1;
`

// ReportsList

export const ReportsTable = styled.div`
  display: grid;
  grid-template-columns: 1rem repeat(7, 1fr) 6rem;
  grid-gap: 0.4rem;
  align-items: center;
  padding: 1rem 0;
`
export const ReportRow = styled.div<{ isActive?: boolean }>`
  grid-column: 1 / -1;
  display: inherit;
  grid-template-columns: inherit;
  grid-gap: inherit;
  padding: 0 1rem;
  background: ${p => (p.isActive ? bg.logoHint : 'transparent')};

  &:hover {
    background: ${bg.hint};
  }
`
export const ReportRowHeader = styled.div`
  grid-column: 1 / -1;
  display: inherit;
  grid-template-columns: inherit;
  grid-gap: inherit;
  padding: 0 1rem;
  align-items: center;
`
export const ReportRowMain = styled.div`
  grid-column: 1 / -1;
  display: inherit;
  grid-template-columns: inherit;
  grid-gap: inherit;
  align-items: center;
  padding: 0.4rem 0;
  cursor: pointer;
  user-select: none;
`
const reportItem = css`
  font-size: 0.9rem;
`
export const ReportName = styled.div`
  ${reportItem}
  font-weight: 600;
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.5rem;
`
export const ReportTemplate = styled.div`
  ${reportItem}
  font-weight: 600;
`
export const ReportStatus = styled.div`
  ${reportItem}
  justify-self: center;
`
export const ReportResponses = styled.div`
  ${reportItem}
`
export const ReportResponse = styled.div<{ status: 'NON_COMPLIANT' | 'NOT_APPLICABLE' }>`
  color: ${p => text[p.status]};
  line-height: 1.2rem;
  font-weight: 600;
`
export const HiddenReportDetail = styled.div<{ isOpen: boolean }>`
  grid-column: 1 / -1;
  padding: 0 1em;
  overflow: hidden;
  height: auto;
  max-height: ${p => (p.isOpen ? '10rem' : '0')};
  transition: max-height 0.3s ease-in-out;
  width: 80%;
  margin: 0 auto;
  & > * {
    margin-bottom: 0.5rem;
  }
`
export const ReportUserRow = styled.div`
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  background: ${bg.light};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2fr;
  align-items: center;
  grid-gap: 1rem;
  padding: 0.4rem 2rem;
`
interface ReportStatus {
  isComplete?: boolean
  status: 'COMPLETE' | 'OVERDUE' | 'PENDING' | 'INACTIVE' | 'COMPLIANT' | 'NON_COMPLIANT'
}
export const UserDetailStatus = styled.div<ReportStatus>`
  color: ${p => (text[p.status] != null ? text[p.status] : text.inactive)};
  font-weight: 600;
`
export const UserDetailNotSubmitted = styled.div`
  font-weight: 200;
  color: ${text.inactive};
  justify-self: center;
`
export const UserDetailTimestamp = styled.div<ReportStatus>`
  font-size: 0.9rem;
  color: ${p => (p.isComplete ? text.main : text[p.status])};
  text-transform: capitalize;
`
export const UserDetailResponse = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
