import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDoubleLeft,
  faAngleRight,
  faBook,
  faBuilding,
  faChartArea,
  faCheckCircle,
  faCog,
  faFileAlt,
  faFire,
  faGhost,
  faListOl,
  faPaperPlane,
  faPencilAlt,
  faPlus,
  faQuestionCircle,
  faSave,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faLock,
} from '@fortawesome/free-solid-svg-icons'

const initIcons = () => {
  library.add(
    faAngleDoubleLeft,
    faAngleRight,
    faBook,
    faBuilding,
    faChartArea,
    faCheckCircle,
    faCog,
    faFileAlt,
    faFire,
    faGhost,
    faListOl,
    faPaperPlane,
    faPencilAlt,
    faPlus,
    faQuestionCircle,
    faSave,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faLock,
  )
}

export default initIcons
