import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { text, bg } from 'utils/colors'

interface ProgressBarProps {
  countTotal: number
  countComplete: number
  adjective?: string
  message?: string | React.ReactElement<any>
  color?: string
}

export const ProgressBarContainer = styled.div`
  margin: 0.5em 0;
  font-size: 0.8em;
  width: 100%;
`
export const TotalBar = styled.div`
  width: 100%;
  height: 0.7em;
  background: ${bg.contentBox};
`
export const CompleteBar = styled.div<{ percentComplete: number; color?: string }>`
  width: ${p => p.percentComplete}%;
  height: 0.7em;
  background: ${p => p.color || text.COMPLETE};
`
export const ProgressMsg = styled.div`
  margin-top: 0.5em;
  text-align: center;
  color: ${text.secondary};
  font-weight: 200;
`

const ProgressBar: React.FC<ProgressBarProps> = ({
  countTotal,
  countComplete,
  adjective,
  message,
  color,
}: ProgressBarProps) => {
  const { t } = useTranslation()
  // const [ value, setValue ] = React.useState<string>('')

  // const dispatch = React.useDispatch()
  // const property = React.useSelector(
  //   (state: store) => state.property
  // )

  if (countTotal < 1) return null

  const percentComplete =
    countComplete != null && countTotal != null ? Math.round((countComplete / countTotal) * 100) : 0

  return (
    <ProgressBarContainer>
      <TotalBar>
        <CompleteBar percentComplete={percentComplete} color={color} />
      </TotalBar>
      <ProgressMsg>
        {message != null ? message : t('progressBarCompletePercent', { percent: percentComplete })}
      </ProgressMsg>
    </ProgressBarContainer>
  )
}

export default ProgressBar
