import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { Auth0Context } from 'utils/auth0'

const ctx = 'app/company'

export const LIST_COMPANIES_LOADING = `${ctx}/LIST_COMPANIES_LOADING`
export const LIST_COMPANIES_SUCCESS = `${ctx}/LIST_COMPANIES_SUCCESS`
export const LIST_COMPANIES_FAILURE = `${ctx}/LIST_COMPANIES_FAILURE`

export const FIND_COMPANY_LOADING = `${ctx}/FIND_COMPANY_LOADING`
export const FIND_COMPANY_SUCCESS = `${ctx}/FIND_COMPANY_SUCCESS`
export const FIND_COMPANY_FAILURE = `${ctx}/FIND_COMPANY_FAILURE`

export const ADD_COMPANY_LOADING = `${ctx}/ADD_COMPANY_LOADING`
export const ADD_COMPANY_SUCCESS = `${ctx}/ADD_COMPANY_SUCCESS`
export const ADD_COMPANY_FAILURE = `${ctx}/ADD_COMPANY_FAILURE`

export const UPDATE_COMPANY_LOADING = `${ctx}/UPDATE_COMPANY_LOADING`
export const UPDATE_COMPANY_SUCCESS = `${ctx}/UPDATE_COMPANY_SUCCESS`
export const UPDATE_COMPANY_FAILURE = `${ctx}/UPDATE_COMPANY_FAILURE`

export const REMOVE_COMPANY_LOADING = `${ctx}/REMOVE_COMPANY_LOADING`
export const REMOVE_COMPANY_SUCCESS = `${ctx}/REMOVE_COMPANY_SUCCESS`
export const REMOVE_COMPANY_FAILURE = `${ctx}/REMOVE_COMPANY_FAILURE`

export const ADD_REPORT_USER_LOADING = `${ctx}/ADD_REPORT_USER_LOADING`
export const ADD_REPORT_USER_SUCCESS = `${ctx}/ADD_REPORT_USER_SUCCESS`
export const ADD_REPORT_USER_FAILURE = `${ctx}/ADD_REPORT_USER_FAILURE`

export const REMOVE_REPORT_USER_LOADING = `${ctx}/REMOVE_REPORT_USER_LOADING`
export const REMOVE_REPORT_USER_SUCCESS = `${ctx}/REMOVE_REPORT_USER_SUCCESS`
export const REMOVE_REPORT_USER_FAILURE = `${ctx}/REMOVE_REPORT_USER_FAILURE`

export const listCompanies = (
  auth0Context: Auth0Context,
  params?: { page?: number; perPage?: number; includeTotal?: boolean },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: LIST_COMPANIES_LOADING })

  const clientId = auth0Context.getClientId()

  let url = `${host}/v1/company?clientId=${clientId}`
  if (params?.page) url += `&page=${params?.page}`
  if (params?.perPage) url += `&perPage=${params?.perPage}`
  if (params?.includeTotal) url += `&includeTotal=${params?.includeTotal}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_COMPANIES_SUCCESS,
        payload: {
          page: data.page,
          perPage: data.perPage,
          totalItems: data.totalItems,
          items: data.items,
        },
      }),
    )
    .catch(err => dispatch({ type: LIST_COMPANIES_FAILURE, payload: { errorMsg: err.message } }))
}

export const findCompany = (auth0Context: Auth0Context, companyId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: FIND_COMPANY_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/company/${companyId}`, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: FIND_COMPANY_SUCCESS,
        payload: {
          company: data.company,
        },
      }),
    )
    .catch(err => dispatch({ type: FIND_COMPANY_FAILURE, payload: { errorMsg: err.message } }))
}

export const addCompany = (
  auth0Context: Auth0Context,
  params: { name: string; templateId?: string; daysToComplete?: number; users?: { email: string; name: string }[] },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: ADD_COMPANY_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/company`, 'POST', { ...params, clientId: auth0Context.getClientId() })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: ADD_COMPANY_SUCCESS,
        payload: {
          company: data.company,
        },
      }),
    )
    .catch(err => dispatch({ type: ADD_COMPANY_FAILURE, payload: { errorMsg: err.message } }))
}

export const updateCompany = (
  auth0Context: Auth0Context,
  companyId: string,
  params: { name?: string; templateId?: string; daysToComplete?: number },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: UPDATE_COMPANY_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/company/${companyId}`, 'PUT', params)
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: UPDATE_COMPANY_SUCCESS,
        payload: {
          company: data.company,
        },
      }),
    )
    .catch(err => dispatch({ type: UPDATE_COMPANY_FAILURE, payload: { errorMsg: err.message } }))
}

export const removeCompany = (auth0Context: Auth0Context, companyId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: REMOVE_COMPANY_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/company/${companyId}`, 'DELETE')
    .then(() =>
      dispatch({
        type: REMOVE_COMPANY_SUCCESS,
        payload: {
          companyId,
        },
      }),
    )
    .catch(err => dispatch({ type: REMOVE_COMPANY_FAILURE, payload: { errorMsg: err.message } }))
}

export const addReportUser = (
  auth0Context: Auth0Context,
  companyId: string,
  user: { name: string; email: string },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: ADD_REPORT_USER_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/company/${companyId}/user`, 'POST', { email: user.email, name: user.name })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: ADD_REPORT_USER_SUCCESS,
        payload: {
          companyId,
          user: data.user,
        },
      }),
    )
    .catch(err => dispatch({ type: ADD_REPORT_USER_FAILURE, payload: { errorMsg: err.message } }))
}

export const removeReportUser = (auth0Context: Auth0Context, companyId: string, userId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: REMOVE_REPORT_USER_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/company/${companyId}/user/${userId}`, 'DELETE')
    .then(() =>
      dispatch({
        type: REMOVE_REPORT_USER_SUCCESS,
        payload: {
          companyId,
          userId,
        },
      }),
    )
    .catch(err => dispatch({ type: REMOVE_REPORT_USER_FAILURE, payload: { errorMsg: err.message } }))
}

export default {
  listCompanies,
  findCompany,
  addCompany,
  updateCompany,
  removeCompany,
  addReportUser,
  removeReportUser,
}
