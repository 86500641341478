import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

import { Auth0Context } from 'utils/auth0'

const ctx = 'api/userReport'

export const LIST_REPORTS_LOADING = `${ctx}/LIST_REPORTS_LOADING`
export const LIST_REPORTS_SUCCESS = `${ctx}/LIST_REPORTS_SUCCESS`
export const LIST_REPORTS_FAILURE = `${ctx}/LIST_REPORTS_FAILURE`

export const FIND_REPORT_LOADING = `${ctx}/FIND_REPORT_LOADING`
export const FIND_REPORT_SUCCESS = `${ctx}/FIND_REPORT_SUCCESS`
export const FIND_REPORT_FAILURE = `${ctx}/FIND_REPORT_FAILURE`

export const CREATE_ATTESTATION_LOADING = `${ctx}/CREATE_ATTESTATION_LOADING`
export const CREATE_ATTESTATION_SUCCESS = `${ctx}/CREATE_ATTESTATION_SUCCESS`
export const CREATE_ATTESTATION_FAILURE = `${ctx}/CREATE_ATTESTATION_FAILURE`

export const UPLOAD_DOCUMENT_LOADING = `${ctx}/UPLOAD_DOCUMENT_LOADING`
export const UPLOAD_DOCUMENT_SUCCESS = `${ctx}/UPLOAD_DOCUMENT_SUCCESS`
export const UPLOAD_DOCUMENT_FAILURE = `${ctx}/UPLOAD_DOCUMENT_FAILURE`

export const REMOVE_DOCUMENT_LOADING = `${ctx}/REMOVE_DOCUMENT_LOADING`
export const REMOVE_DOCUMENT_SUCCESS = `${ctx}/REMOVE_DOCUMENT_SUCCESS`
export const REMOVE_DOCUMENT_FAILURE = `${ctx}/REMOVE_DOCUMENT_FAILURE`

export const DOWNLOAD_DOCUMENT_LOADING = `${ctx}/DOWNLOAD_DOCUMENT_LOADING`
export const DOWNLOAD_DOCUMENT_SUCCESS = `${ctx}/DOWNLOAD_DOCUMENT_SUCCESS`
export const DOWNLOAD_DOCUMENT_FAILURE = `${ctx}/DOWNLOAD_DOCUMENT_FAILURE`

export const SIGN_REPORT_LOADING = `${ctx}/SIGN_REPORT_LOADING`
export const SIGN_REPORT_SUCCESS = `${ctx}/SIGN_REPORT_SUCCESS`
export const SIGN_REPORT_FAILURE = `${ctx}/SIGN_REPORT_FAILURE`

export const listReports = (
  auth0Context: Auth0Context,
  companyId: string,
  params?: { page?: number; perPage?: number; includeTotal?: boolean },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: LIST_REPORTS_LOADING })

  let url = `${host}/v1/report?companyId=${companyId}`
  if (params?.page) url += `&page=${params?.page}`
  if (params?.perPage) url += `&perPage=${params?.perPage}`
  if (params?.includeTotal) url += `&includeTotal=${params?.includeTotal}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: LIST_REPORTS_SUCCESS,
        payload: {
          page: data.page,
          perPage: data.perPage,
          totalItems: data.totalItems,
          items: data.items,
        },
      }),
    )
    .catch(err => dispatch({ type: LIST_REPORTS_FAILURE, payload: { errorMsg: err.message } }))
}

export const findReport = (auth0Context: Auth0Context, reportId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: FIND_REPORT_LOADING })

  const url = `${host}/v1/report/${reportId}`

  await auth0Context
    .makeRequest(url, 'GET')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: FIND_REPORT_SUCCESS,
        payload: {
          report: data.report,
        },
      }),
    )
    .catch(err => dispatch({ type: FIND_REPORT_FAILURE, payload: { errorMsg: err.message } }))
}

export const createAttestation = (
  auth0Context: Auth0Context,
  reportId: string,
  params: { attestation: string; explanation: string; sectionId: string; questionId: string },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: CREATE_ATTESTATION_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/report/${reportId}/attestation`, 'POST', {
      ...params,
    })
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: CREATE_ATTESTATION_SUCCESS,
        payload: {
          attestation: data.attestation,
          sectionId: params.sectionId,
          questionId: params.questionId,
        },
      }),
    )
    .catch(err => dispatch({ type: CREATE_ATTESTATION_FAILURE, payload: { errorMsg: err.message } }))
}

export const signReport = (auth0Context: Auth0Context, reportId: string) => async (
  dispatch: ThunkDispatch<{}, {}, Action>,
  getState: Function,
  { host }: { host: string },
): Promise<void> => {
  dispatch({ type: SIGN_REPORT_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/report/${reportId}/sign`, 'POST')
    .then(res => res.json())
    .then(data =>
      dispatch({
        type: SIGN_REPORT_SUCCESS,
        payload: {
          signed: data.signed,
        },
      }),
    )
    .catch(err => dispatch({ type: SIGN_REPORT_FAILURE, payload: { errorMsg: err.message } }))
}

export const removeDocument = (
  auth0Context: Auth0Context,
  {
    reportId,
    documentId,
    sectionId,
    questionId,
  }: { reportId: string; documentId: string; sectionId: string; questionId: string },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: REMOVE_DOCUMENT_LOADING })

  await auth0Context
    .makeRequest(
      `${host}/v1/report/${reportId}/document/${documentId}?sectionId=${sectionId}&questionId=${questionId}`,
      'DELETE',
    )
    .then(() =>
      dispatch({
        type: REMOVE_DOCUMENT_SUCCESS,
        payload: {
          sectionId,
          questionId,
          documentId,
        },
      }),
    )
    .catch(err => dispatch({ type: REMOVE_DOCUMENT_FAILURE, payload: { errorMsg: err.message } }))
}

export const uploadDocument = (
  auth0Context: Auth0Context,
  reportId: string,
  file: File,
  params: { name: string; type: string; sectionId: string; questionId: string },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: UPLOAD_DOCUMENT_LOADING })

  await auth0Context
    .makeRequest(`${host}/v1/report/${reportId}/document`, 'POST', {
      name: params.name,
      type: params.type,
      sectionId: params.sectionId,
      questionId: params.questionId,
    })
    .then(res => res.json())
    .then(async data => {
      const uploadResponse = await fetch(data.url, {
        method: 'PUT',
        headers: {
          'Content-Type': params.type,
        },
        body: file,
      })

      if (!uploadResponse.ok) {
        dispatch({ type: UPLOAD_DOCUMENT_FAILURE, payload: { errorMsg: 'AWS Error.' } })

        return removeDocument(auth0Context, {
          reportId,
          documentId: data.document.id,
          sectionId: params.sectionId,
          questionId: params.questionId,
        })
      }

      dispatch({
        type: UPLOAD_DOCUMENT_SUCCESS,
        payload: {
          reportId,
          sectionId: params.sectionId,
          questionId: params.questionId,
          reportDocument: data.document,
        },
      })
    })
    .catch(err => dispatch({ type: UPLOAD_DOCUMENT_FAILURE, payload: { errorMsg: err.message } }))
}

export const downloadDocument = (
  auth0Context: Auth0Context,
  {
    reportId,
    sectionId,
    questionId,
    documentId,
  }: { reportId: string; documentId: string; sectionId: string; questionId: string },
) => async (dispatch: ThunkDispatch<{}, {}, Action>, getState: Function, { host }: { host: string }): Promise<void> => {
  dispatch({ type: DOWNLOAD_DOCUMENT_LOADING })

  await auth0Context
    .makeRequest(
      `${host}/v1/report/${reportId}/document/${documentId}?sectionId=${sectionId}&questionId=${questionId}`,
      'GET',
    )
    .then(res => res.json())
    .then(async data => {
      const downloadResponse = await fetch(data.url, {
        method: 'GET',
      })

      if (!downloadResponse.ok) {
        throw new Error('DOWNLOAD ERROR')
      }

      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(await downloadResponse.blob())
      a.download = data.document.name
      document.body.appendChild(a)
      a.click()
      a.remove()

      dispatch({
        type: DOWNLOAD_DOCUMENT_SUCCESS,
      })
    })
    .catch(err => dispatch({ type: DOWNLOAD_DOCUMENT_FAILURE, payload: { errorMsg: err.message } }))
}
