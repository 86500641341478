import styled, { css } from 'styled-components'
import { text, bg, bgOpaque, colors } from 'utils/colors'
import { TITLEBAR_HEIGHT, ATTESTATION_WIDTH, REPORT_HEADER_HEIGHT, REPORT_QUESTION_HEIGHT } from 'utils/appUnits'

import { PageContent, ContentBox, ColumnHeading, MiniTitle } from 'components/styles'

// templates/TemplateView

const titleBanner = css`
  padding: 0 1rem;
  background: ${bgOpaque.logo};
  height: ${TITLEBAR_HEIGHT};
  display: grid;
  align-items: center;
  grid-gap: 1rem;
`
export const TemplateNormalTitle = styled.div`
  ${titleBanner}
  grid-template-columns: auto 6rem 10rem 10rem 10rem 2rem;
`
export const TemplateViewOnlyTitle = styled.div`
  ${titleBanner}
  grid-template-columns: auto 6rem 10rem 10rem 2rem;
`
export const TemplateEditTitle = styled.div`
  ${titleBanner}
  grid-template-columns: auto;
  display: flex;
`
export const ViewReportTitle = styled.div`
  ${titleBanner}
  grid-template-columns: 1fr 1fr 2rem;
`
export const TemplatePageTitle = styled.h1`
  font-weight: 200;
  margin: 0;
  align-items: center;
  & > * {
    display: block;
  }
  @media (min-width: 90rem) {
    /* (90rem) == 1440px) */
    font-size: 1.5rem;
    display: flex;
  }
`
export const TemplateName = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  @media (min-width: 90rem) {
    /* (90rem) == 1440px) */
    margin-left: 0.5rem;
  }
`
export const ToggleEditIcon = styled.div`
  margin-left: 1rem;
  font-size: 0.8rem;
  color: ${text.inactive};
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${text.active};
  }
`
export const CountLength = styled.div`
  color: ${text.inactive};
  font-weight: 600;
  font-size: 1.1rem;
  justify-self: center;
`
export const ViewOnlyMode = styled.div`
  color: ${text.inactive};
  text-align: center;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-around;
  &:hover {
    color: ${text.active};
  }
`

// template/TemplateForm

export const sectionsTable = css`
  padding: 1rem 2rem 5rem;
  margin: 0 0 1rem;
`
export const SectionsTableContainer = styled.div`
  ${sectionsTable}
`
export const SectionsTableForm = styled.form`
  ${sectionsTable}
  padding: 0;
`
export const TemplateSectionContainer = styled(PageContent)`
  margin-bottom: 1rem;
  padding-top: 0;
  padding-bottom: 0;
`
export const SectionName = styled.div<{ isViewOnly?: boolean }>`
  font-size: 1.5rem;
  font-weight: 600;
  display: grid;
  grid-template-columns: ${p => (p.isViewOnly ? '1rem auto 10rem' : '1rem auto 10rem 10rem 1rem')};
  grid-gap: 1rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.6rem 0.4rem;
`

export const SectionToggleName = styled.div`
  display: flex;
  align-items: center;
`

export const ReportSectionName = styled(SectionName)`
  grid-template-columns: 1rem 1fr 1fr;
`
export const ToggleName = styled.div`
  cursor: pointer;
  user-select: none;
`
export const SectionBadges = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: row-reverse;
  & > * {
    margin-left: 1rem;
  }
`
export const HiddenContentBox = styled(ContentBox)<{ isOpen?: boolean }>`
  margin-top: ${p => (p.isOpen ? '1rem' : '0')};
  padding-bottom: ${p => (p.isOpen ? '1rem' : '0')};

  transition: all 0.3s ease-in-out;
  & > * {
    height: auto;
    overflow-x: hidden;
    overflow-y: ${p => (p.isOpen ? 'scroll' : 'hidden')};
    padding: ${p => (p.isOpen ? '0.5rem 0' : '0 0')};
    max-height: ${p => (p.isOpen ? 'auto' : '0')};
    transform: ${p => (p.isOpen ? 'scaleY(1)' : 'scaleY(0)')};

    transition: all 0.3s ease-in-out;
    display: ${p => (p.isOpen ? 'flex' : 'none !important')};
  }
`
export const QuestionField = styled.div<{ isLeftAligned?: boolean }>`
  padding: 1rem 0;
  border-radius: 0.5rem;
  justify-self: ${p => (p.isLeftAligned ? 'start' : 'center')};
`
export const CheckboxWrapper = styled.div`
  padding-top: 0.45rem;
`
export const DocDescripWrapper = styled.div<{ isActive?: boolean }>`
  visibility: ${p => (p.isActive ? 'visible' : 'hidden')};
`
export const RemoveBtnWrapper = styled.div`
  padding-top: 0.9rem;
  text-align: center;
  justify-self: center;
`

export const HiddenContentBoxWithAnswerSlider = styled(ContentBox)<{ isOpen?: boolean }>`
  margin-top: ${p => (p.isOpen ? '1rem' : '0')};
  transition: all 0.5s ease-in-out;
  position: relative;
  * {
    padding: ${p => (p.isOpen ? '' : '0')};
    height: ${p => (p.isOpen ? '' : '0')};
    transition: all 0.5s ease-in-out;
  }
`
export const QuestionContentSide = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  & > * {
    height: auto;
    overflow-x: hidden;
    overflow-y: ${p => (p.isOpen ? 'scroll' : 'hidden')};
    padding: ${p => (p.isOpen ? '0.5rem 0 1rem' : '0 0')};
    height: ${p => (p.isOpen ? REPORT_QUESTION_HEIGHT : '0')};
    transition: all 0.3s ease-in-out;
  }
`
export const AnswerContentSide = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;

  overflow: hidden;
  width: ${p => (p.isExpanded ? `calc(2 * ${ATTESTATION_WIDTH} + 6rem)` : `calc(${ATTESTATION_WIDTH} + 6rem)`)};
  transition: width 0.5s ease-in-out;

  background: ${bgOpaque.active};
  display: flex;
`
export const ExpandBar = styled.div<{ isExpanded: boolean }>`
  background: ${text.active};
  color: ${text.contrast};
  width: 3rem;
  height: 100%;
  padding: 0;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  & > svg {
    font-size: 1.6rem;
    line-height: 0;
    align-self: center;
    transform: ${p => (p.isExpanded ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.5s ease-in-out;
    margin: 1rem 0;
  }
`
export const ExpandBarContent = styled.div<{ isExpanded: boolean }>`
  transform: rotate(270deg);
  transform-origin: 50% 50%;
  justify-self: center;
  align-self: center;
  font-size: 0.9rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  text-transform: uppercase;
`
const rowHeights = css`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`
const questionTableGrid = css<{ extrasLength: number }>`
  display: grid;
  grid-template-columns: 3fr repeat(${p => p.extrasLength}, 1fr) 3rem 1.5rem 1fr 3rem 1rem;
  grid-gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
`
export const SectionHeader = styled.div`
  ${rowHeights}
  ${questionTableGrid}
  align-items: center;
  height: auto;
`

export const QuestionRow = styled.div`
  ${rowHeights}
  ${questionTableGrid}
  align-items: start;
  &:hover {
    background: ${bg.main};
  }
`
export const QuestionRowReadOnly = styled(QuestionRow)`
  border-top: 1px solid ${colors.border};
  align-items: center;
`
export const AnswerContentWrapper = styled.div`
  padding: 0 0 1rem;
  width: 100%;
  display: flex;
`
export const AnswerContent = styled.div<{ isExpanded: boolean }>`
  overflow: hidden;
  width: ${p => (p.isExpanded ? '100%' : '0')};
  opacity: ${p => (p.isExpanded ? '1' : '0')};
  transform: ${p => (p.isExpanded ? 'scaleX(1)' : 'scaleX(0)')};
  transition: all 0.3s ease-in-out;

  & > * {
    ${rowHeights}
  }
`
export const StatusBadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin: 0.25rem;
  }
`
export const AvatarsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin: 0.25rem -0.25rem;
  }
`
export const ReportColumnHeading = styled(ColumnHeading)`
  height: ${REPORT_HEADER_HEIGHT};
  display: flex;
  align-items: center;
`
export const AttestationHeading = styled(ColumnHeading)`
  height: ${REPORT_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
`
export const ExpandedAttestationHeading = styled.div<{ count: number }>`
  height: ${REPORT_HEADER_HEIGHT};
  width: calc(${p => p.count} * ${ATTESTATION_WIDTH});
  display: flex;

  & > * {
    width: ${ATTESTATION_WIDTH};
  }
`
export const AttestationRow = styled.div`
  height: ${REPORT_QUESTION_HEIGHT};
  border-top: 1px solid ${colors.border};
  padding: 0.5rem 0;
  justify-self: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ExpandedAttestationRow = styled.div<{ count: number }>`
  height: ${REPORT_QUESTION_HEIGHT};
  border-top: 1px solid ${colors.border};
  padding: 0.5rem 0;
  justify-self: center;
  width: 100%;
  display: flex;
  align-content: center;
  width: calc(${p => p.count} * ${ATTESTATION_WIDTH});
`
export const AttestationContent = styled.div`
  width: ${ATTESTATION_WIDTH};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const UserStatus = styled.div<{ status: 'COMPLETE' | 'PENDING' | 'OVERDUE' }>`
  font-weight: 600;
  color: ${p => text[p.status]};
`
export const AttestationDetailTitle = styled(MiniTitle)`
  color: ${text.inactive};
  margin: 0 0 0.25rem;
`
export const ExplanationWrapper = styled.div`
  margin: 1rem 20% 0;
  width: 60%;
`
export const ExplanationContent = styled.div`
  font-size: 0.9rem;
`
export const DocumentsWrapper = styled.div`
  margin: 1rem 20% 0;
  width: 60%;
`
export const DocumentItem = styled.div<{ onClick: (docId: string) => void }>`
  font-size: 0.9rem;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${text.active};
    text-decoration: underline;
  }
`
export const EditNameWrapper = styled.div`
  display: flex;
  align-items: center;
  input {
    font-size: 1.5rem;
    margin: 0 1rem;
    width: 20rem;
  }
  svg {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
`
