import { map, reject, concat } from 'lodash'

import { Client, Metrics, Invite, ClientUser } from '../models/client'

import {
  FIND_CLIENT_LOADING,
  FIND_CLIENT_SUCCESS,
  FIND_CLIENT_FAILURE,
  LIST_METRICS_LOADING,
  LIST_METRICS_SUCCESS,
  LIST_METRICS_FAILURE,
  ADD_USER_LOADING,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  REMOVE_USER_LOADING,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAILURE,
} from 'actions/clientActions'

interface ClientState {
  error: object
  loading: string[]

  client: {
    isFetching: boolean
    item: { id: string; users: ClientUser[]; invites: Invite[] } | Client

    error?: string
    lastUpdated?: Date
  }

  metrics: {
    isFetching: boolean
    item: {} | Metrics

    error?: string
    lastUpdated?: Date
  }
}

const initState: ClientState = {
  error: {},
  loading: [],

  client: {
    isFetching: false,
    item: { id: '', users: [], invites: [] },
  },

  metrics: {
    isFetching: false,
    item: {},
  },
}

export const clientReducer = (state = initState, action: any): ClientState => {
  switch (action.type) {
    case FIND_CLIENT_LOADING:
      return {
        ...state,

        client: {
          ...state.client,

          isFetching: true,
          error: undefined,
        },
      }

    case FIND_CLIENT_SUCCESS:
      return {
        ...state,

        client: {
          item: {
            ...action.payload.client,
          },

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case FIND_CLIENT_FAILURE:
      return {
        ...state,

        client: {
          ...state.client,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case LIST_METRICS_LOADING:
      return {
        ...state,

        metrics: {
          ...state.metrics,

          error: undefined,
          isFetching: true,
        },
      }

    case LIST_METRICS_SUCCESS:
      return {
        ...state,

        metrics: {
          item: action.payload.metrics,

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case LIST_METRICS_FAILURE:
      return {
        ...state,

        metrics: {
          ...state.metrics,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case ADD_USER_LOADING:
      return {
        ...state,

        client: {
          ...state.client,

          error: undefined,
          isFetching: true,
        },
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,

        client: {
          ...state.client,

          item: {
            ...state.client.item,
            users: state.client.item.users.filter(user => user.id !== action.payload.invite.id),
            invites: concat(
              [],
              reject(state.client.item.invites, { id: action.payload.invite.id }),
              action.payload.invite,
            ),
          },

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case ADD_USER_FAILURE:
      return {
        ...state,

        client: {
          ...state.client,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case REMOVE_USER_LOADING:
      return {
        ...state,

        client: {
          ...state.client,

          error: undefined,
          isFetching: true,
        },
      }

    case REMOVE_USER_SUCCESS:
      return {
        ...state,

        client: {
          ...state.client,

          item: {
            ...state.client.item,
            users: map(state.client.item.users, (user: ClientUser) =>
              user.id === action.payload.userId
                ? {
                    ...user,
                    status: 'REMOVED',
                  }
                : user,
            ),
            invites: state.client.item.invites.filter((invite: Invite) => invite.id !== action.payload.userId),
          },

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case REMOVE_USER_FAILURE:
      return {
        ...state,

        client: {
          ...state.client,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    default:
      return state
  }
}
