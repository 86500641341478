import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import Spinner from 'components/Spinner'
import Sidenav from 'app/Sidenav'
import Titlebar from 'app/Titlebar'
import PrivateRoute from 'components/PrivateRoute'

import { useAuth0 } from '../utils/auth0'
import { isUndefined } from 'lodash'

import DashboardPage from 'features/dashboard/DashboardPage'
import PortfolioPage from 'features/portfolio/PortfolioPage'
import OrgSettingsPage from 'features/orgSettings/OrgSettingsPage'

import TemplateView from 'features/template/TemplateView'
import CompanyView from 'features/portfolio/CompanyView'
import ReportView from 'features/template/ReportView'

import UserReportsList from 'features/reportUser/UserReportsList'
import UserReportView from 'features/reportUser/UserReportView'

import ComponentLibrary from 'features/tempComponentLibrary/ComponentLibrary'
import SettingsModal from './SettingsModal'
import { AppContainer, MainContent } from './styles'

const App: React.FC = () => {
  const { user, isInitializing } = useAuth0()
  const [isInstructionsOpen, toggleInstructionsOpen] = React.useState<boolean>(false)

  if (isInitializing) return <Spinner />

  sessionStorage.setItem('user', JSON.stringify(user))

  if (!isUndefined(user)) sessionStorage.setItem('app_metadata', JSON.stringify(user['https://app_metadata.com/']))

  // if (user.isReportUser) {
  //   return (
  //     <Router>
  //       <AppContainer>
  //         <UserSidenav isShown={isPath === UserReportView } />
  //         <Titlebar toggleModal={() => toggleInstructionsOpen(true)} />
  //         <MainContent>
  //           <Switch>
  //             <PrivateRoute exact path="/" component={UserReportsList} />
  //             <PrivateRoute path="/report/:reportId?" component={UserReportView} />
  //           </Switch>
  //         </MainContent>
  //         <SettingsModal isOpen={isInstructionsOpen} onClose={() => toggleInstructionsOpen(false)} />
  //       </AppContainer>
  //     </Router>
  //   )
  // }

  return (
    <Router>
      <AppContainer>
        <Sidenav />
        <Titlebar toggleModal={() => toggleInstructionsOpen(true)} />
        <MainContent>
          <Switch>
            <PrivateRoute exact path="/" component={DashboardPage} />
            <PrivateRoute path="/portfolio" component={PortfolioPage} />
            <PrivateRoute path="/settings" component={OrgSettingsPage} />
            <PrivateRoute path="/components" component={ComponentLibrary} />
            <PrivateRoute path="/template/:templateId?" component={TemplateView} />
            <PrivateRoute path="/company/:companyId?" component={CompanyView} />
            <PrivateRoute path="/report/:reportId?" component={ReportView} />

            <PrivateRoute path="/userReportsList" component={UserReportsList} />
            <PrivateRoute path="/userReport/:reportId?" component={UserReportView} />
          </Switch>
        </MainContent>
        <SettingsModal isOpen={isInstructionsOpen} onClose={() => toggleInstructionsOpen(false)} />
      </AppContainer>
    </Router>
  )
}

export default App
