import React, { SyntheticEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from 'utils/auth0'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isString, isEqual, isEmpty, size, map, reject, reduce, every } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm, Controller } from 'react-hook-form'
import { TextField, Checkbox } from '@material-ui/core'
import { v4 as uuid } from 'uuid'

import { findTemplate, addTemplate, updateTemplate } from 'actions/templateActions'

import Spinner from 'components/Spinner'
import Button from 'components/Button'
import Instructions from './Instructions'
import ErrorView from 'components/ErrorView'
import ConfirmDialog from 'components/ConfirmDialog'
import Modal from 'components/Modal'

import { ColumnHeading, PageWithBannerTitle, BannerTitle, ToggleIcon } from 'components/styles'

import { Template, Section, Question } from 'models/template'

import {
  TemplateNormalTitle,
  TemplateViewOnlyTitle,
  TemplateEditTitle,
  TemplatePageTitle,
  TemplateName,
  ToggleEditIcon,
  CountLength,
  ViewOnlyMode,
  SectionsTableForm,
  TemplateSectionContainer,
  SectionName,
  SectionToggleName,
  HiddenContentBox,
  SectionHeader,
  QuestionRow,
  CheckboxWrapper,
  DocDescripWrapper,
  RemoveBtnWrapper,
} from './styles'

interface TemplateViewProps {
  match: {
    params: {
      templateId?: string
    }
  }
}

const defaultValues = {
  Native: '',
  TextField: '',
  Select: '',
  ReactSelect: { value: 'vanilla', label: 'Vanilla' },
  Checkbox: false,
  switch: false,
  RadioGroup: '',
}

const TemplateView: React.FC<TemplateViewProps> = ({
  match: {
    params: { templateId },
  },
}: TemplateViewProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false)
  const [hasChanges, setHasChanges] = React.useState<boolean>(false)
  const [isTitleEditing, setIsTitleEditing] = React.useState<boolean>(false)
  const [openSectionTitleIndex, setOpenSectionTitleIndex] = React.useState<number | undefined>(undefined)
  const [openSectionIndex, setOpenSectionIndex] = React.useState<number | undefined>(0)
  const [isInstructionsOpen, toggleInstructionsOpen] = React.useState<boolean>(false)
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const [showViewOnlyModal, setShowViewOnlyModal] = React.useState<boolean>(false)
  const [submitErrorMsg, setSubmitErrorMsg] = React.useState<string | undefined>(undefined)

  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const { control, watch, handleSubmit } = useForm({ defaultValues })

  const { templateDetail } = useSelector((state: any) => state.templates)

  const placeholderTemplate = { name: 'New Template', instructions: '', sections: [], isInUse: false }
  const [templateInPlace, setTemplateInPlace] = React.useState<
    Template | { name: string; instructions: string; sections: Section[]; isInUse: boolean }
  >(placeholderTemplate)

  React.useEffect(() => {
    if (isString(templateId)) dispatch(findTemplate(auth0Context, templateId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, auth0Context])

  React.useEffect(() => {
    if (isSaving) {
      const hasError = templateDetail?.addError || templateDetail?.updateError
      if (hasError) return setIsSaving(false)

      return history.push('/settings')
    }

    if (templateId && templateDetail?.item && !hasChanges) {
      setTemplateInPlace(templateDetail.item)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, templateDetail, history, templateInPlace.name])

  const isUpdateView = isString(templateId)
  const isViewOnly = isUpdateView && templateInPlace?.isInUse

  const handleUpdateTemplateName = (e: SyntheticEvent): void => {
    const { value: name } = e.target as HTMLTextAreaElement
    console.log('handleUpdateTemplateName', name)

    setTemplateInPlace({
      ...templateInPlace,
      name,
    })

    setHasChanges(true)
  }

  const handleSectionOpen = (sectionIndex: number) => {
    if (openSectionIndex === sectionIndex) {
      setOpenSectionIndex(undefined)
    } else {
      setOpenSectionIndex(sectionIndex)
    }
  }

  const handleUpdateSectionName = (e: SyntheticEvent): void => {
    const { value: name } = e.target as HTMLTextAreaElement
    console.log('handleUpdateSectionName', name)
    setTemplateInPlace({
      ...(templateInPlace as Template),
      sections: map(templateInPlace?.sections, (section: Section, index) =>
        index === openSectionTitleIndex
          ? {
              ...section,
              name,
            }
          : section,
      ),
    })
    setOpenSectionTitleIndex(undefined)
    setHasChanges(true)
  }

  const handleAddSection = () => {
    console.log('handleAddSection()')

    setTemplateInPlace({
      ...(templateInPlace as Template),

      sections: templateInPlace.sections.concat({
        id: uuid(),
        name: 'New Section',
        columnNames: ['Action/Decision', 'Approval Required When', 'Company Approval', 'Trive Approval'],
        questions: [
          {
            id: uuid(),
            columns: ['', '', '', ''],
            allowNA: false,
            allowDocuments: false,
          },
        ],
      }) as Section[],
    })

    // setOpenSectionTitleIndex(templateInPlace.sections.length)
    setHasChanges(true)
  }

  const handleRemoveSection = (sectionToRemove: Section): void => {
    console.log('handleRemoveSection()', sectionToRemove)

    setTemplateInPlace({
      ...(templateInPlace as Template),

      sections: reject(templateInPlace?.sections, { id: sectionToRemove.id }),
    })

    setHasChanges(true)
  }

  const onSubmit = (data: {}) => {
    console.log('onSubmit()')

    setIsSaving(true)

    if (!templateInPlace.instructions) return setSubmitErrorMsg('MISSING_INSTRUCTIONS')

    const sections = reduce(
      data,
      (arr: Section[], val: string | boolean, key: string) => {
        const parts = key.split('-')

        const sectionIndex = parseInt(parts[0])
        const questionIndex = parseInt(parts[1])
        const columnName = parts[2]

        const section = arr[sectionIndex] || {
          name: templateInPlace.sections[sectionIndex].name,
          columnNames: templateInPlace.sections[sectionIndex].columnNames,
          questions: [],
        }

        const question = section.questions[questionIndex] || {
          allowNA: false,
          allowDocuments: false,
          columns: [],
        }

        if (columnName === 'allowNA') {
          question.allowNA = val as boolean
        } else if (columnName === 'allowDocuments') {
          question.allowDocuments = val as boolean
        } else if (columnName === 'documentDescription') {
          question.documentDescription = val as string
        } else {
          question.columns.push(val)
        }

        section.questions[questionIndex] = question
        arr[sectionIndex] = section

        return arr
      },
      [],
    )

    const allColumnsMatch = every(sections, (section: Section) =>
      every(
        section.questions,
        (question: Question) =>
          isEqual(section.columnNames.length, question.columns.length) &&
          every(question.columns, column => !isEmpty(column)),
      ),
    )

    if (!allColumnsMatch) return setSubmitErrorMsg('INVALID_COLUMNS')

    if (templateId) {
      dispatch(
        updateTemplate(auth0Context, templateId, {
          name: templateInPlace.name,
          instructions: templateInPlace.instructions,
          sections,
        }),
      )
    } else {
      dispatch(
        addTemplate(auth0Context, {
          name: templateInPlace.name,
          instructions: templateInPlace.instructions,
          sections,
        }),
      )
    }
  }

  const handleCancelWithChanges = () => {
    console.log('handleCancelWithChanges()')

    history.goBack()
  }

  const handleInstructionsClose = (instructions: string): void => {
    toggleInstructionsOpen(false)
    const hasChanges = instructions !== templateInPlace?.instructions

    if (hasChanges) {
      setTemplateInPlace({
        ...(templateInPlace as Template),

        instructions,
      })

      setHasChanges(true)
    }
  }

  const handleAddQuestion = (e: SyntheticEvent, sIndex: number): void => {
    e.stopPropagation()
    console.log('handleAddQuestion()', sIndex)

    setTemplateInPlace({
      ...(templateInPlace as Template),

      sections: map(templateInPlace?.sections, (section, index) =>
        index === sIndex
          ? {
              ...section,
              questions: section.questions.concat({
                id: uuid(),
                columns: ['', '', '', ''],
                allowNA: false,
                allowDocuments: false,
              }),
            }
          : section,
      ),
    })

    setOpenSectionIndex(sIndex)
    setHasChanges(true)
  }

  const handleRemoveQuestion = (questionToRemoveId: string): void => {
    console.log('handleRemoveQuestion()', questionToRemoveId)

    setTemplateInPlace({
      ...(templateInPlace as Template),

      sections: map(templateInPlace?.sections, section => ({
        ...section,

        questions: reject(section.questions, { id: questionToRemoveId }),
      })),
    })

    setHasChanges(true)
  }

  let availableActions = (
    <TemplateNormalTitle>
      <TemplatePageTitle>
        {t('reportTemplateTitle')}
        <TemplateName>
          {templateInPlace?.name}
          {isViewOnly ? null : (
            <ToggleEditIcon onClick={() => setIsTitleEditing(true)}>
              <FontAwesomeIcon icon="pencil-alt" />
            </ToggleEditIcon>
          )}
        </TemplateName>
      </TemplatePageTitle>
      <CountLength>{t('templateSectionLength', { count: size(templateInPlace?.sections) })}</CountLength>
      <Button onClick={handleAddSection} isInverted color="active">
        <>
          <FontAwesomeIcon icon="plus" />
          {t('addSectionBtn')}
        </>
      </Button>
      <Button onClick={() => toggleInstructionsOpen(true)} isInverted color="secondary">
        <>
          <FontAwesomeIcon icon="list-ol" />
          {t('editInstructionsBtn')}
        </>
      </Button>
      <Button isInverted onClick={handleSubmit(onSubmit)} color="success">
        <>
          <FontAwesomeIcon icon="save" />
          {t('saveAndCloseBtn')}
        </>
      </Button>
      {hasChanges ? (
        <Button onClick={() => setShowConfirmCancelDialog(true)} color="secondary" size="large" isSimple>
          <FontAwesomeIcon icon="times" />
        </Button>
      ) : (
        <Button to="/settings" color="secondary" size="large" isSimple>
          <FontAwesomeIcon icon="times" />
        </Button>
      )}
    </TemplateNormalTitle>
  )
  if (isViewOnly) {
    availableActions = (
      <TemplateViewOnlyTitle>
        <TemplatePageTitle>
          {t('reportTemplateTitle')}
          <TemplateName>{templateInPlace?.name}</TemplateName>
        </TemplatePageTitle>
        <CountLength>{t('templateSectionLength', { count: size(templateInPlace?.sections) })}</CountLength>
        <ViewOnlyMode onClick={() => setShowViewOnlyModal(true)}>
          {t('viewOnlyModeMsg')}
          <FontAwesomeIcon icon="question-circle" />
        </ViewOnlyMode>
        <Button onClick={() => toggleInstructionsOpen(true)} isInverted color="secondary">
          <>
            <FontAwesomeIcon icon="list-ol" />
            {t('editInstructionsBtn')}
          </>
        </Button>
        <Button to="/settings" color="secondary" size="large" isSimple>
          <FontAwesomeIcon icon="times" />
        </Button>
      </TemplateViewOnlyTitle>
    )
  }
  if (isTitleEditing) {
    availableActions = (
      <TemplateEditTitle>
        <input name="templateName" type="text" defaultValue={templateInPlace?.name} onBlur={handleUpdateTemplateName} />
        <Button onClick={() => setIsTitleEditing(false)} size="small" color="success">
          {t('submitBtn')}
        </Button>
      </TemplateEditTitle>
    )
  }

  let content
  if (templateDetail?.isFetching) {
    content = <Spinner />
  } else if (templateDetail?.error) {
    content = <ErrorView errorMsg={t(templateDetail.error)} />
  } else {
    content = (
      <SectionsTableForm onSubmit={e => e.preventDefault()}>
        <PageWithBannerTitle>
          <BannerTitle>{availableActions}</BannerTitle>

          {submitErrorMsg ? <ErrorView errorMsg={t(submitErrorMsg)} /> : null}
          {templateDetail?.addError ? <ErrorView errorMsg={t(templateDetail.addError)} /> : null}
          {templateDetail?.updateError ? <ErrorView errorMsg={t(templateDetail.updateError)} /> : null}

          {map(templateInPlace?.sections, (section: Section, sIndex: number) => (
            <TemplateSectionContainer key={`section-${sIndex}`}>
              <SectionName onClick={() => handleSectionOpen(sIndex)} isViewOnly={isViewOnly}>
                <ToggleIcon isOpen={openSectionIndex === sIndex}>
                  <FontAwesomeIcon icon="angle-right" />
                </ToggleIcon>
                <SectionToggleName>
                  {openSectionTitleIndex === sIndex ? (
                    <>
                      <input
                        name={`sectionName-${sIndex}`}
                        type="text"
                        defaultValue={section.name}
                        onBlur={handleUpdateSectionName}
                      />
                      <Button onClick={() => setOpenSectionTitleIndex(undefined)} size="small" color="success">
                        {t('submitBtn')}
                      </Button>
                    </>
                  ) : (
                    <>
                      {section.name}
                      {isViewOnly ? null : (
                        <ToggleEditIcon onClick={() => setOpenSectionTitleIndex(sIndex)}>
                          <FontAwesomeIcon icon="pencil-alt" />
                        </ToggleEditIcon>
                      )}
                    </>
                  )}
                </SectionToggleName>
                <CountLength>{t('questionsLength', { count: size(section.questions) })}</CountLength>
                {isViewOnly ? null : (
                  <Button onClick={e => handleAddQuestion(e, sIndex)} color="active">
                    <>
                      <FontAwesomeIcon icon="plus" />
                      {t('addQuestionBtn')}
                    </>
                  </Button>
                )}
                {isViewOnly ? null : (
                  <Button onClick={() => handleRemoveSection(section)} color="error" size="small" isSimple>
                    <FontAwesomeIcon icon="trash-alt" />
                  </Button>
                )}
              </SectionName>

              <HiddenContentBox isOpen={openSectionIndex === sIndex}>
                <SectionHeader extrasLength={3}>
                  <ColumnHeading key={`heading-${sIndex}-actionDecision`}>{t('actionDecisionHeading')}</ColumnHeading>
                  <ColumnHeading key={`heading-${sIndex}-approvalRequiredWhen`}>
                    {t('approvalRequiredWhenHeading')}
                  </ColumnHeading>
                  <ColumnHeading key={`heading-${sIndex}-companyApproval`}>{t('companyApprovalHeading')}</ColumnHeading>
                  <ColumnHeading key={`heading-${sIndex}-triveApproval`}>{t('triveApprovalHeading')}</ColumnHeading>
                  <ColumnHeading>{t('allowNAColumnHeading')}</ColumnHeading>
                  <ColumnHeading isDoubleCol>{t('docRequiredColumnHeading')}</ColumnHeading>
                  <ColumnHeading key={`heading-${sIndex}-remove`}></ColumnHeading>
                </SectionHeader>
                {map(section.questions, (quest: Question, qIndex: number) => {
                  const watchAllowDocs = watch(
                    `${sIndex}-${qIndex}-allowDocuments`,
                    quest.allowDocuments ? 'true' : undefined,
                  )
                  return (
                    <QuestionRow key={`question-${sIndex}-${qIndex}`} extrasLength={size(quest.columns) - 1}>
                      <Controller
                        key={`input-${sIndex}-${qIndex}-0`}
                        as={TextField}
                        name={`${sIndex}-${qIndex}-0`}
                        control={control}
                        variant="outlined"
                        multiline
                        defaultValue={quest.columns[0]}
                      />

                      <Controller
                        key={`input-${sIndex}-${qIndex}-1`}
                        as={TextField}
                        name={`${sIndex}-${qIndex}-1`}
                        control={control}
                        variant="outlined"
                        onBlur={() => setSubmitErrorMsg(undefined)}
                        defaultValue={quest.columns[1]}
                      />

                      <Controller
                        key={`input-${sIndex}-${qIndex}-2`}
                        as={TextField}
                        name={`${sIndex}-${qIndex}-2`}
                        control={control}
                        variant="outlined"
                        multiline
                        onBlur={() => setSubmitErrorMsg(undefined)}
                        defaultValue={quest.columns[2]}
                      />

                      <Controller
                        key={`input-${sIndex}-${qIndex}-3`}
                        as={TextField}
                        name={`${sIndex}-${qIndex}-3`}
                        control={control}
                        variant="outlined"
                        multiline
                        onBlur={() => setSubmitErrorMsg(undefined)}
                        defaultValue={quest.columns[3]}
                      />

                      <CheckboxWrapper>
                        <Controller
                          as={Checkbox}
                          name={`${sIndex}-${qIndex}-allowNA`}
                          control={control}
                          color="default"
                          onBlur={() => setSubmitErrorMsg(undefined)}
                          defaultValue={quest.allowNA}
                        />
                      </CheckboxWrapper>
                      <CheckboxWrapper>
                        <Controller
                          as={Checkbox}
                          name={`${sIndex}-${qIndex}-allowDocuments`}
                          control={control}
                          color="default"
                          onBlur={() => setSubmitErrorMsg(undefined)}
                          defaultValue={quest.allowDocuments}
                        />
                      </CheckboxWrapper>
                      <DocDescripWrapper isActive={!!watchAllowDocs}>
                        <Controller
                          as={TextField}
                          name={`${sIndex}-${qIndex}-documentDescription`}
                          control={control}
                          variant="outlined"
                          multiline
                          onBlur={() => setSubmitErrorMsg(undefined)}
                          defaultValue={quest.documentDescription}
                          value={''}
                        />
                      </DocDescripWrapper>

                      {isViewOnly ? null : (
                        <RemoveBtnWrapper>
                          <Button
                            onClick={() => handleRemoveQuestion(quest.id as string)}
                            isSimple
                            size="small"
                            color="error"
                          >
                            <FontAwesomeIcon icon="trash-alt" />
                          </Button>
                        </RemoveBtnWrapper>
                      )}
                    </QuestionRow>
                  )
                })}
              </HiddenContentBox>
            </TemplateSectionContainer>
          ))}
        </PageWithBannerTitle>

        <Instructions
          initialVal={templateInPlace?.instructions}
          isOpen={isInstructionsOpen}
          onClose={updatedInstructions => handleInstructionsClose(updatedInstructions)}
          isViewOnly={isViewOnly}
        />

        <Modal
          isOpen={showViewOnlyModal}
          title={<>{t('viewOnlyModeMsg')}</>}
          content={<>{t('viewOnlyModeExplained')}</>}
          onClose={() => setShowViewOnlyModal(false)}
          buttons={
            <Button onClick={() => setShowViewOnlyModal(false)} size="small" color="secondary">
              {t('okayBtn')}
            </Button>
          }
        />
        <ConfirmDialog
          isOpen={!!showConfirmCancelDialog}
          confirmText={t('confirmCancelWithChanges')}
          onConfirm={handleCancelWithChanges}
          onCancel={() => setShowConfirmCancelDialog(false)}
        ></ConfirmDialog>
      </SectionsTableForm>
    )
  }

  return content
}

export default TemplateView
