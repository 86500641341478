import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { text, bg, bgInverted, activeMultiplier } from 'utils/colors'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ButtonProps {
  children?: string | React.ReactElement<any>
  uniqueId?: string
  type?: string | React.ReactElement<any>
  color?: 'logo' | 'active' | 'success' | 'error' | 'warning' | 'secondary'
  size?: 'small' | 'large'
  isContrast?: boolean
  isInverted?: boolean
  isDisabled?: boolean
  isSimple?: boolean
  to?: string
  onClick?: (event?: any) => void
}

interface ButtonStyleT {
  uniqueId?: string
  color?: 'logo' | 'active' | 'success' | 'error' | 'warning' | 'secondary'
  size?: 'small' | 'large'
  contrast?: boolean
  inverted?: boolean
  disabled?: boolean
  simple?: string
}

const sizeMap = {
  small: '0.7em',
  large: '1.2em',
}

const simpleSizeMap = {
  small: '1em',
  large: '1.6em',
}

const ButtonWrapper = styled.div`
  font-size: 16px;
`
const buttonStyles = css`
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: none;
  cursor: pointer;
  user-select: none;

  color: ${(p: ButtonStyleT) => (!p.color ? text.active : p.inverted ? text.contrast : text[p.color])};
`
const fullButtonStyles = css`
  font-size: ${(p: ButtonStyleT) => (p.size && sizeMap[p.size] ? sizeMap[p.size] : '1em')};
  background: ${(p: ButtonStyleT) =>
    p.disabled
      ? bgInverted.disabled
      : p.contrast
      ? bg.contentBox
      : !p.color
      ? bg.active
      : p.inverted
      ? bgInverted[p.color]
      : bg[p.color]};
  padding: ${(p: ButtonStyleT) =>
    p.size && sizeMap[p.size] ? `${sizeMap[p.size]} calc(2*${sizeMap[p.size]})` : '0.75em 0'};
  width: ${(p: ButtonStyleT) => (p.size && sizeMap[p.size] ? 'auto' : '10em')};

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
  font-weight: 700;
  opacity: 1;

  &:hover {
    opacity: ${activeMultiplier};
  }
  & > svg {
    margin: 0 0.5em;
    font-size: 1.25em;
  }
`
const simpleButtonStyles = css`
  font-size: ${(p: ButtonStyleT) => (p.size && simpleSizeMap[p.size] ? simpleSizeMap[p.size] : '1.2em')};
  background: transparent;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
`
const StyledButton = styled.button`
  ${buttonStyles}
  ${(p: ButtonStyleT) => (p.simple ? simpleButtonStyles : fullButtonStyles)}
`

const LinkButton = styled(Link)`
  ${buttonStyles}
  ${(p: ButtonStyleT) => (p.simple ? simpleButtonStyles : fullButtonStyles)}
`

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  // if (props.isSimple) {
  //   return (
  //     <SimpleButton onClick={props.onClick} color={props.color} data-cy={props.uniqueId}>
  //       {props.children}
  //     </SimpleButton>
  //   )
  // }

  let buttonContent: string | React.ReactElement<any> =
    'Invalid Button: Requires one of prop: "to", "type=\'submit\'" or "onClick"'

  if (props.to) {
    buttonContent = (
      <LinkButton
        to={props.to}
        data-cy={props.uniqueId}
        color={props.color}
        size={props.size}
        contrast={props.isContrast}
        inverted={props.isInverted}
        disabled={props.isDisabled}
        simple={props.isSimple ? 'true' : undefined}
      >
        {props.children}
      </LinkButton>
    )
  }

  if (!!props.onClick || props.type === 'submit') {
    buttonContent = (
      <StyledButton
        type={props.type === 'submit' ? 'submit' : 'button'}
        onClick={props.onClick}
        data-cy={props.uniqueId}
        color={props.color}
        size={props.size}
        contrast={props.isContrast}
        inverted={props.isInverted}
        disabled={props.isDisabled}
        simple={props.isSimple ? 'true' : undefined}
      >
        {props.children}
      </StyledButton>
    )
  }

  return <ButtonWrapper>{buttonContent}</ButtonWrapper>
}

export default Button
