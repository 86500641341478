import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { map, isEmpty, includes, head, last, filter, add } from 'lodash'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { text } from 'utils/colors'
import { listMetrics } from 'actions/clientActions'
import { useAuth0 } from 'utils/auth0'
import calcPercent from 'utils/calcPercent'
import { VictoryChart, VictoryPie, VictoryAxis, VictoryStack, VictoryBar, VictoryTooltip } from 'victory'

import Spinner from 'components/Spinner'
import ErrorView from 'components/ErrorView'
import EmptyView from 'components/EmptyView'
import StatusBadge from 'components/StatusBadge'
import ProgressBar from 'components/ProgressBar'
import Modal from 'components/Modal'

import CountdownTimer from './CountdownTimer'
import mockDashboardData from './mockDashboardData'

import { PageContent, MiniTitle } from 'components/styles'
import {
  DashboardContainer,
  DataBox,
  DataBoxTitle,
  DataItemsList,
  DataItem,
  DataItemLabel,
  PieWrapper,
  BarWrapper,
  DataItemGroup,
  HistoryItem,
  SmallPieWrapper,
  PiesList,
  EmptyWrapper,
  DateDueWrapper,
  DashboardEmptyContent,
} from './styles'

dayjs.extend(relativeTime)

const DashboardPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const { metrics } = useSelector((state: any) => state.client)

  useEffect(() => {
    dispatch(listMetrics(auth0Context))
  }, [dispatch, auth0Context])

  if (metrics.error) {
    return <ErrorView errorMsg={t('listMetricsError')} serverResponse={t(metrics.error)} />
  }

  if (metrics.isFetching) {
    return <Spinner />
  }

  if (isEmpty(metrics) || isEmpty(metrics.item.reports)) {
    return <EmptyView emptyMsg={t('noMetricsMsg')} />
  }

  let reportsList = metrics.item.reports
  const isMetricsEmpty = isEmpty(metrics.item.reports)
  if (isMetricsEmpty) {
    reportsList = mockDashboardData
  }

  const currentReport = head(reportsList) as any
  if (!currentReport) return null

  const currentReportData = map(
    currentReport.reportStatusCounts,
    (count: number, key: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => ({
      count,
      label: t(`${key}-label`, { percent: calcPercent(count, currentReport.totalReportsSent) }),
      color: text[key],
    }),
  )

  const numComplete = add(currentReport.reportStatusCounts.COMPLIANT, currentReport.reportStatusCounts.NON_COMPLIANT)
  const daysUntilFirst = dayjs().diff(dayjs(head(currentReport.dueByDates)), 'day')
  const daysUntilLast = dayjs().diff(dayjs(last(currentReport.dueByDates)), 'day')
  const dueDateString =
    daysUntilFirst === daysUntilLast
      ? dayjs(head(currentReport.dueByDates)).fromNow()
      : `${dayjs().diff(daysUntilFirst, 'day')} - ${dayjs().diff(daysUntilLast, 'day')} Days`

  let stacksValues = {
    OVERDUE: 0,
    PENDING: 0,
    NON_COMPLIANT: 0,
    COMPLIANT: 0,
  }
  const stacksData = map(stacksValues, (val: number, status: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => {
    const stackData = map(reportsList, rep => {
      stacksValues = {
        ...stacksValues,
        [status]: add(stacksValues[status], rep.reportStatusCounts[status]),
      }
      return {
        x: rep.name,
        y: rep.reportStatusCounts[status] || 0,
        color: text[status],
        label: `${rep.reportStatusCounts[status] || 0} ${t(status)}`,
      }
    })
    return (
      <VictoryBar
        key={`barGraph-${status}`}
        data={stackData}
        labelComponent={<VictoryTooltip />}
        barWidth={64}
        style={{
          data: { fill: ({ datum }) => datum.color },
          labels: {
            fontSize: 14,
            fontFamily: 'Roboto',
            fontWeight: 600,
            fill: ({ datum }) => datum.color,
          },
        }}
      />
    )
  })

  const complianceData = filter(
    map(
      currentReport.reportStatusCounts,
      (count: number, key: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => {
        if (count > 0 && includes(['COMPLIANT', 'NON_COMPLIANT'], key)) {
          return {
            count,
            label: t(`${key}-report`, { count }),
            color: text[key],
          }
        }
      },
    ),
    (i: any) => i != null,
  )

  const attestationData = filter(
    map(currentReport.questionStatistics, (count: number, key: 'COMPLIANT' | 'NON_COMPLIANT' | 'NOT_APPLICABLE') => {
      if (count > 0) {
        return {
          count,
          label: t(`${key}-attestation`, { count }),
          color: text[key],
        }
      }
    }),
    (i: any) => i != null,
  )

  return (
    <PageContent>
      <DashboardContainer>
        <DataBox>
          <DataBoxTitle>{t('bigChartTitle', { reportName: currentReport.name })}</DataBoxTitle>
          <DataItemsList>
            <DataItem>
              <DataItemLabel>{t('totalReportsLabel')}</DataItemLabel>
              {currentReport.totalReportsSent}
            </DataItem>
            <DataItem>
              <DataItemLabel>{t('responsesDueLabel')}</DataItemLabel>
              {dueDateString}
            </DataItem>
            <DataItem>
              <DataItemLabel>{t('outstandingReportsLabel')}</DataItemLabel>
              <DataItemGroup>
                {currentReport.reportStatusCounts.PENDING > 0 && (
                  <StatusBadge status="PENDING" count={currentReport.reportStatusCounts.PENDING} />
                )}
                {currentReport.reportStatusCounts.OVERDUE > 0 && (
                  <StatusBadge status="OVERDUE" count={currentReport.reportStatusCounts.OVERDUE} />
                )}
              </DataItemGroup>
            </DataItem>
            <DataItem>
              <DataItemLabel>{t('totalCompletionLabel')}</DataItemLabel>
              <DataItemGroup>
                <ProgressBar
                  countTotal={currentReport.totalReportsSent}
                  countComplete={numComplete}
                  message={`${numComplete} of ${currentReport.totalReportsSent}`}
                  color={text.logo}
                />
              </DataItemGroup>
            </DataItem>
          </DataItemsList>
          <PieWrapper>
            <VictoryPie
              data={currentReportData}
              x="label"
              y="count"
              labelComponent={<VictoryTooltip constrainToVisibleArea />}
              style={{
                data: { fill: ({ datum }) => datum.color },
                labels: {
                  fontSize: 16,
                  fontFamily: 'Roboto',
                  fontWeight: 600,
                  fill: ({ datum }) => datum.color,
                },
              }}
              animate={{
                duration: 1000,
                onLoad: { duration: 500 },
              }}
            />
          </PieWrapper>
        </DataBox>
        <DataBox>
          <DataBoxTitle>{t('previousReportsChartTitle')}</DataBoxTitle>
          <BarWrapper>
            <VictoryChart
              domainPadding={50}
              animate={{
                duration: 1000,
                onLoad: { duration: 500 },
              }}
            >
              <VictoryAxis
                tickValues={map(reportsList, rep => rep.id)}
                tickFormat={map(reportsList, rep => rep.name)}
                style={{
                  tickLabels: {
                    fontSize: 12,
                    fontFamily: 'Roboto',
                    fontWeight: 600,
                  },
                }}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={x => x}
                style={{
                  tickLabels: {
                    fontSize: 12,
                    fontFamily: 'Roboto',
                  },
                }}
              />
              <VictoryStack>{stacksData}</VictoryStack>
            </VictoryChart>
          </BarWrapper>
          <DataItemGroup>
            {map(stacksValues, (count: number, status: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => {
              return (
                <HistoryItem key={`status-${status}`}>
                  <StatusBadge status={status} count={count} size="lg" />
                </HistoryItem>
              )
            })}
          </DataItemGroup>
        </DataBox>
        <DataBox>
          <DataBoxTitle>{t('complianceOverviewChartTitle', { reportName: currentReport.name })}</DataBoxTitle>
          <PiesList>
            <SmallPieWrapper>
              <MiniTitle>{t('reportsBreakdown')}</MiniTitle>
              {isEmpty(complianceData) ? (
                <EmptyWrapper>
                  <EmptyView emptyMsg={t('noReportsCompleteBreakdownMsg')} />
                </EmptyWrapper>
              ) : (
                <VictoryPie
                  data={complianceData}
                  x="label"
                  y="count"
                  labelComponent={<VictoryTooltip constrainToVisibleArea />}
                  style={{
                    data: { fill: ({ datum }) => datum.color },
                    labels: {
                      fontSize: 24,
                      fontFamily: 'Roboto',
                      fontWeight: 600,
                      fill: ({ datum }) => datum.color,
                    },
                  }}
                  animate={{
                    duration: 1000,
                    onLoad: { duration: 500 },
                  }}
                />
              )}
            </SmallPieWrapper>
            <SmallPieWrapper>
              <MiniTitle>{t('attestationsBreakdown')}</MiniTitle>
              {isEmpty(attestationData) ? (
                <EmptyWrapper>
                  <EmptyView emptyMsg={t('noReportsCompleteBreakdownMsg')} />
                </EmptyWrapper>
              ) : (
                <VictoryPie
                  data={attestationData}
                  x="label"
                  y="count"
                  labelComponent={<VictoryTooltip constrainToVisibleArea />}
                  style={{
                    data: { fill: ({ datum }) => datum.color },
                    labels: {
                      fontSize: 24,
                      fontFamily: 'Roboto',
                      fontWeight: 600,
                      fill: ({ datum }) => datum.color,
                    },
                  }}
                  animate={{
                    duration: 1000,
                    onLoad: { duration: 500 },
                  }}
                />
              )}
            </SmallPieWrapper>
          </PiesList>
        </DataBox>
        {!isMetricsEmpty && (
          <DataBox>
            <DataBoxTitle>{t('nextReportChartTitle')}</DataBoxTitle>
            <CountdownTimer date={metrics.item.nextSendDate} />
            <DateDueWrapper>{dayjs(metrics.item.nextSendDate).format('MMMM D, YYYY - h:mma')}</DateDueWrapper>
          </DataBox>
        )}
      </DashboardContainer>
      <Modal
        isOpen={isMetricsEmpty}
        content={
          <DashboardEmptyContent>
            <h1>{t('dashboardEmptyModalTitle')}</h1>
            <p>{t('dashboardEmptyModalMsg')}</p>
            <h3>{t('nextReportChartTitle')}</h3>
            <CountdownTimer date={metrics.item.nextSendDate} />
            <DateDueWrapper>{dayjs(metrics.item.nextSendDate).format('MMMM D, YYYY - h:mma')}</DateDueWrapper>
          </DashboardEmptyContent>
        }
        hasOverlay={true}
      />
    </PageContent>
  )
}

export default DashboardPage
