import styled from 'styled-components'
import { text } from 'utils/colors'

// OrgSettingsPage

export const OrgSettingsContent = styled.div`
  display: grid;
  grid-template-columns: auto 23rem;
  grid-gap: 1rem;
`

// TemplateMgmt

export const TemplatesTable = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  align-items: center;
  min-width: 0;

  grid-template-columns: 1rem auto auto auto 5.5rem 3rem 1rem;
  @media (min-width: 80rem) {
    /* (80rem == 1280) */
    grid-template-columns: 1rem auto auto auto 5.5rem 3rem 1rem;
  }
  @media (min-width: 90rem) {
    /* (90rem == 1440px) */
    grid-template-columns: 1rem auto auto auto 5.5rem 3rem 1rem;
  }
`
export const TemplateItemIcon = styled.div`
  color: ${text.logo};
  font-size: 1.5rem;
  justify-self: center;
`
const TemplateCell = styled.div`
  justify-self: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
`
export const TemplateCellName = styled(TemplateCell)`
  font-weight: 600;
  padding: 1rem 0;
`
export const TemplateCellNumber = styled(TemplateCell)`
  font-size: 0.8rem;
  color: ${text.secondary};
  display: none;
  @media (min-width: 80rem) {
    /* (80rem == 1280) */
    display: block;
  }
`
export const TemplateCellModified = styled(TemplateCell)`
  display: none;
  @media (min-width: 90rem) {
    /* (90rem == 1440px) */
    display: grid;
    grid-template-columns: auto auto;
    font-size: 0.8rem;
    overflow: hidden;
    min-width: 0;
    grid-gap: 0.3rem;
    align-items: center;
    text-transform: capitalize;
  }
`
export const TemplateDuplicateBtn = styled.div``
export const TemplateEditBtn = styled.div``
export const CannotRemoveMsg = styled.div`
  margin: 1rem 0;
  & > ul {
    margin: 1rem;
    list-style-type: disc;
  }
  li {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
`

// AdminMgmt

// hides the last child which should be a HorizontalRule Component
export const AdminTable = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto) 2.5rem;
  grid-gap: 0.5rem;
  justify-items: start;
  align-items: center;
  min-height: 0;
  min-width: 0;

  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    max-width: 100%;
  }
`
export const UserButton = styled.div`
  justify-self: end;
`
export const InviteMsg = styled.div`
  color: ${text.active};
  font-weight: 600;
  font-size: 0.6rem;
  text-transform: uppercase;
  white-space: normal;
  text-align: center;
`
