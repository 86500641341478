import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAuth0 } from 'utils/auth0'
import {
  findReport,
  createAttestation,
  uploadDocument,
  removeDocument,
  downloadDocument,
  signReport,
} from 'actions/userReportActions'

import ErrorView from 'components/ErrorView'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import { PageTitle } from 'components/styles'

const UserReportViewContainer = styled.div``

const UserReportView: React.FC<{}> = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const auth0Context = useAuth0()

  const { reportDetail } = useSelector((state: any) => state.userReports)

  const handleSignReport = ({ reportId }: { reportId: string }): void => {
    console.log('handleSignReport')
    dispatch(signReport(auth0Context, reportId))
  }

  const handleCreateAttestation = ({
    reportId,
    attestation,
    explanation,
    sectionId,
    questionId,
  }: {
    reportId: string
    attestation: string
    explanation: string
    sectionId: string
    questionId: string
  }): void => {
    console.log('handleCreateAttestation', reportId)
    dispatch(createAttestation(auth0Context, reportId, { attestation, explanation, sectionId, questionId }))
  }

  const handleRemoveDocument = (): void => {
    console.log('handleRemoveDocument')
    dispatch(
      removeDocument(auth0Context, {
        reportId: '5e729cf89a2e053bcbde5f0f',
        documentId: '5e986f1aa606354084472f5c',
        sectionId: '5e726dbdec0bf5555386cf9a',
        questionId: '5e7292d448bfb464b228d2ad',
      }),
    )
  }

  const handleDownloadDocument = (): void => {
    console.log('handleDownloadDocument')
    dispatch(
      downloadDocument(auth0Context, {
        reportId: '5e729cf89a2e053bcbde5f0f',
        sectionId: '5e726dbdec0bf5555386cf9a',
        questionId: '5e7292d448bfb464b228d2ad',
        documentId: '5e986f1aa606354084472f5c',
      }),
    )
  }

  const getMimeType = (file: File): string => {
    return file.type
  }

  const handleUploadDocument = async (e: any): Promise<void> => {
    console.log('handleUploadDocument', e)

    const files = [...e.target.files]

    await Promise.all(
      files.map(async file => {
        console.log('FILE NAME:', file.name)
        console.log('FILE TYPE:', getMimeType(file))
        dispatch(
          uploadDocument(auth0Context, '5e729cf89a2e053bcbde5f0f', file, {
            name: file.name,
            type: getMimeType(file),
            sectionId: '5e726dbdec0bf5555386cf9a',
            questionId: '5e7292d448bfb464b228d2ad',
          }),
        )
      }),
    )
  }

  React.useEffect(() => {
    dispatch(findReport(auth0Context, '5e729cf89a2e053bcbde5f0f'))
  }, [dispatch, auth0Context])

  if (reportDetail.error) {
    return <ErrorView />
  }

  if (reportDetail.isFetching) {
    return <Spinner />
  }

  return (
    <UserReportViewContainer>
      <PageTitle>User Report Detail View</PageTitle>
      {JSON.stringify(reportDetail.item)}
      <Button
        onClick={() => {
          handleSignReport({
            reportId: '5e729cf89a2e053bcbde5f0f',
          })
        }}
        color="success"
      >
        {t('Sign Report')}
      </Button>

      <Button
        onClick={() => {
          handleCreateAttestation({
            reportId: '5e729cf89a2e053bcbde5f0f',
            attestation: 'NON_COMPLIANT',
            explanation: 'test 2',
            sectionId: '5e726dbdec0bf5555386cf9a',
            questionId: '5e7292d448bfb464b228d2ad',
          })
        }}
      >
        {t('Create Attestation')}
      </Button>

      <Button
        onClick={() => {
          handleRemoveDocument()
        }}
        color="success"
      >
        {t('Remove Document')}
      </Button>

      <Button
        onClick={() => {
          handleDownloadDocument()
        }}
      >
        {t('Download Document')}
      </Button>

      <input type="file" onChange={handleUploadDocument} />
    </UserReportViewContainer>
  )
}

export default UserReportView
