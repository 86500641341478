import { combineReducers } from 'redux'

import { clientReducer } from './clientReducer'
import { templateReducer } from './templateReducer'
import { companyReducer } from './companyReducer'
import { reportReducer } from './reportReducer'
import { userReportReducer } from './userReportReducer'
import { userReducer } from './userReducer'

const rootReducer = combineReducers({
  client: clientReducer,
  templates: templateReducer,
  companies: companyReducer,
  reports: reportReducer,
  userReports: userReportReducer,
  user: userReducer,
})

export default rootReducer
