import styled from 'styled-components'
import { text, bg } from 'utils/colors'
import { ContentBox } from 'components/styles'

// DashboardPage

export const DashboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: 80rem) {
    /* (80em == 1280px) */
    grid-template-columns: 1fr 1fr;
  }
`
export const DataBox = styled(ContentBox)`
  padding: 1rem;
`
export const DataBoxTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 1rem;
`
export const DataItemsList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`
export const DataItem = styled.div`
  padding: 1rem;
  background: ${bg.active};
  border-radius: 0.5rem;
  font-weight: 600;
  color: ${text.logo};
  text-align: center;
`
export const DataItemLabel = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: ${text.secondary};
  margin: 0 0 0.5rem;
`
export const PieWrapper = styled.div`
  width: 70%;
  height: auto;
  margin: 0 auto 1rem;
`
export const SmallPieWrapper = styled.div`
  width: 40%;
  text-align: center;
`
export const PiesList = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BarWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto 1rem;
`
export const DataItemGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  & > * {
    margin: 0.5rem;
  }
`
export const HistoryItem = styled.div`
  margin: 1rem;
`
export const EmptyWrapper = styled.div`
  margin: 5rem 0 0;
`
export const DateDueWrapper = styled.div`
  font-size: 1.4rem;
  color: ${text.inactive};
  font-style: italic;
  font-weight: 600;
  text-align: center;
  margin: 3rem auto 1rem;
`
export const DashboardEmptyContent = styled.div`
  h1 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 2rem;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: 0 0 2rem;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 2rem;
  }
`
