import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { map, size, countBy, find, includes, isEmpty } from 'lodash'
import { UserSection, UserQuestion, Attestation } from 'models/report'
import { User } from 'models/common'

import { downloadDocument } from 'actions/userReportActions'

import { findReport } from 'actions/reportActions'
import Spinner from 'components/Spinner'
import Button from 'components/Button'
import StatusBadge from 'components/StatusBadge'
import Avatar from 'components/Avatar'
import EmptyView from 'components/EmptyView'
import ErrorView from 'components/ErrorView'

import { ToggleIcon, PageWithBannerTitle, BannerTitle } from 'components/styles'
import {
  ViewReportTitle,
  TemplatePageTitle,
  TemplateName,
  SectionsTableContainer,
  TemplateSectionContainer,
  ReportSectionName,
  ToggleName,
  SectionBadges,
  HiddenContentBoxWithAnswerSlider,
  SectionHeader,
  QuestionRowReadOnly,
  QuestionField,
  QuestionContentSide,
  AnswerContentSide,
  ExpandBar,
  ExpandBarContent,
  AnswerContentWrapper,
  AnswerContent,
  StatusBadgesContainer,
  AvatarsContainer,
  ReportColumnHeading,
  AttestationHeading,
  ExpandedAttestationHeading,
  AttestationRow,
  ExpandedAttestationRow,
  AttestationContent,
  UserStatus,
  AttestationDetailTitle,
  ExplanationWrapper,
  ExplanationContent,
  DocumentsWrapper,
  DocumentItem,
} from './styles'

interface ReportViewProps {
  match: {
    params: {
      reportId?: string
    }
  }
}

const ReportView: React.FC<ReportViewProps> = ({
  match: {
    params: { reportId },
  },
}: ReportViewProps) => {
  const { t } = useTranslation()
  const [openSection, setOpenSection] = React.useState<number | undefined>(0)
  const [isAttestationExpanded, setIsAttestationExpanded] = React.useState<boolean>(true)

  const dispatch = useDispatch()
  const auth0Context = useAuth0()

  const { reportDetail } = useSelector((state: any) => state.reports)

  React.useEffect(() => {
    if (reportId) dispatch(findReport(auth0Context, reportId))
  }, [reportId, dispatch, auth0Context])

  const handleSectionOpen = (sectionIndex: number) => {
    if (openSection === sectionIndex) {
      setOpenSection(undefined)
    } else {
      setOpenSection(sectionIndex)
    }
  }

  const handleDownloadDoc = (reportId: string, sectionId: string, questionId: string, documentId: string): void => {
    console.log('handleDownloadDoc()', reportId, sectionId, questionId, documentId)
    dispatch(downloadDocument(auth0Context, { reportId, sectionId, questionId, documentId }))
  }

  if (reportDetail?.error) {
    return <ErrorView />
  }
  if (reportDetail?.isFetching) {
    return <Spinner />
  }

  if (isEmpty(reportDetail?.item)) return <EmptyView emptyMsg={t('noReportsToListMsg')} />

  // const hardCodedHeadings = (
  //   <SectionHeader extrasLength={6}>
  //     <ColumnHeading key={`heading-${sIndex}-actionDecision`}>{t('actionDecisionHeading')}</ColumnHeading>
  //     <ColumnHeading key={`heading-${sIndex}-approvalRequiredWhen`}>{t('approvalRequiredWhenHeading')}</ColumnHeading>
  //     <ColumnHeading key={`heading-${sIndex}-companyApproval`}>{t('companyApprovalHeading')}</ColumnHeading>
  //     <ColumnHeading key={`heading-${sIndex}-triveApproval`}>{t('triveApprovalHeading')}</ColumnHeading>
  //     <ColumnHeading>{t('allowNAColumnHeading')}</ColumnHeading>
  //     <ColumnHeading isDoubleCol>{t('docRequiredColumnHeading')}</ColumnHeading>
  //     <ColumnHeading>{t('removeColumnHeading')}</ColumnHeading>
  //   </SectionHeader>
  // )

  const renderExplanation = (explanation?: string) => {
    if (isEmpty(explanation)) return null
    return (
      <ExplanationWrapper>
        <AttestationDetailTitle>{t('explanationTitle')}</AttestationDetailTitle>
        <ExplanationContent>{explanation}</ExplanationContent>
      </ExplanationWrapper>
    )
  }

  const renderDocuments = (reportId: string, sectionId: string, questionId: string, documents?: any[]) => {
    if (isEmpty(documents)) return null
    return (
      <DocumentsWrapper>
        <AttestationDetailTitle>{t('documentsTitle')}</AttestationDetailTitle>
        {map(documents, doc => (
          <DocumentItem
            key={`doc-${doc.id}`}
            onClick={() => handleDownloadDoc(reportId, sectionId, questionId, doc.id)}
          >
            {doc?.name}
          </DocumentItem>
        ))}
      </DocumentsWrapper>
    )
  }

  const renderSection = (section: UserSection, sIndex: number) => {
    return (
      <TemplateSectionContainer key={`section-${sIndex}`}>
        <ReportSectionName onClick={() => handleSectionOpen(sIndex)}>
          <ToggleIcon isOpen={openSection === sIndex}>
            <FontAwesomeIcon icon="angle-right" />
          </ToggleIcon>
          <ToggleName>{section.name}</ToggleName>
          <SectionBadges>
            <StatusBadge status="NON_COMPLIANT" count={section.questionStatistics?.NON_COMPLIANT} />
            <StatusBadge status="NOT_APPLICABLE" count={section.questionStatistics?.NOT_APPLICABLE} />
          </SectionBadges>
        </ReportSectionName>
        <HiddenContentBoxWithAnswerSlider isOpen={openSection === sIndex}>
          <QuestionContentSide isOpen={openSection === sIndex}>
            <SectionHeader extrasLength={size(section.columnNames) - 1}>
              {map(section.columnNames, (colName: string, headingIndex: number) => (
                <ReportColumnHeading key={`heading-${sIndex}-${headingIndex}`} isLeftAligned={headingIndex === 0}>
                  {colName}
                </ReportColumnHeading>
              ))}
            </SectionHeader>
            {map(section.questions, (quest: UserQuestion, qIndex: number) => {
              return (
                <QuestionRowReadOnly key={`question-${sIndex}-${qIndex}`} extrasLength={size(quest.columns) - 1}>
                  {map(quest.columns, (colAnswer: string, colIndex: number) => (
                    <QuestionField key={`field-${sIndex}-${qIndex}-${colIndex}`} isLeftAligned={colIndex === 0}>
                      {colAnswer}
                    </QuestionField>
                  ))}
                </QuestionRowReadOnly>
              )
            })}
          </QuestionContentSide>
          <AnswerContentSide isExpanded={isAttestationExpanded}>
            <ExpandBar
              onClick={() => setIsAttestationExpanded(!isAttestationExpanded)}
              isExpanded={isAttestationExpanded}
            >
              <FontAwesomeIcon icon="angle-double-left" />
              <ExpandBarContent isExpanded={isAttestationExpanded}>
                {isAttestationExpanded
                  ? t('hideAttestationDetail', { count: size(section?.questions) })
                  : t('showAttestationDetail', { count: size(section?.questions) })}
              </ExpandBarContent>
              <FontAwesomeIcon icon="angle-double-left" />
            </ExpandBar>
            <AnswerContentWrapper>
              <AnswerContent isExpanded={isAttestationExpanded}>
                <ExpandedAttestationHeading count={size(reportDetail?.item?.users)}>
                  {map(reportDetail?.item?.users, (usr: User) => {
                    const status = includes(['COMPLIANT', 'NON_COMPLIANT'], usr.reportStatus)
                      ? 'COMPLETE'
                      : usr.reportStatus === 'OVERDUE'
                      ? 'OVERDUE'
                      : 'PENDING'
                    let secondaryText
                    if (includes(['COMPLETE', 'OVERDUE', 'PENDING'], status)) {
                      secondaryText = <UserStatus status={status}>{t(status as string)}</UserStatus>
                    }
                    return (
                      <AttestationContent key={`avatar-${usr.id}`}>
                        <Avatar user={usr} secondaryText={secondaryText} isCircleSmall />
                      </AttestationContent>
                    )
                  })}
                </ExpandedAttestationHeading>
                {map(section.questions, (quest: UserQuestion) => (
                  <ExpandedAttestationRow key={`attest-expanded-${quest.id}`} count={size(reportDetail?.item?.users)}>
                    {map(reportDetail?.item?.users, (usr: User) => {
                      const userAttestation = find(quest.attestations, (att: Attestation) => att.userId === usr.id)
                      if (
                        userAttestation &&
                        includes(['COMPLIANT', 'NON_COMPLIANT', 'NOT_APPLICABLE'], userAttestation?.attestation)
                      ) {
                        return (
                          <AttestationContent key={`attestation-${quest.id}-${usr.id}`}>
                            <StatusBadge status={userAttestation.attestation} size="lg" />
                            {renderExplanation(userAttestation.explanation)}
                            {renderDocuments(
                              reportDetail?.item.id,
                              section.id as string,
                              quest.id as string,
                              userAttestation.documents,
                            )}
                          </AttestationContent>
                        )
                      } else {
                        return (
                          <AttestationContent key={`attestation-${quest.id}-${usr.id}`}>
                            <EmptyView emptyMsg={t('notSubmittedMsg')} />
                          </AttestationContent>
                        )
                      }
                    })}
                  </ExpandedAttestationRow>
                ))}
              </AnswerContent>

              <AnswerContent isExpanded={!isAttestationExpanded}>
                <AttestationHeading>{t('attestationHeading')}</AttestationHeading>
                {map(section.questions, (quest: UserQuestion, qIndex: number) => {
                  const statusCounts = countBy(quest.attestations, 'attestation')
                  console.log('statusCounts', statusCounts)
                  return (
                    <AttestationRow key={`attest-${quest.id}`}>
                      <AttestationContent>
                        <StatusBadgesContainer>
                          {map(
                            statusCounts,
                            (count: number, status: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE') => {
                              if (count > 0) {
                                return (
                                  <React.Fragment key={`count-${quest.id}-${status}`}>
                                    <StatusBadge status={status} />
                                  </React.Fragment>
                                )
                              }
                            },
                          )}
                        </StatusBadgesContainer>
                        <AvatarsContainer>
                          {map(quest.attestations, (att: Attestation) => {
                            const userObj = find(section.users, { id: att.userId })
                            if (userObj) {
                              return (
                                <React.Fragment key={`attest-${att.questionId}-${att.sectionId}-${att.userId}`}>
                                  <Avatar user={userObj} status={att.attestation} isImageOnly isSmall />
                                </React.Fragment>
                              )
                            }
                          })}
                        </AvatarsContainer>
                      </AttestationContent>
                    </AttestationRow>
                  )
                })}
              </AnswerContent>
            </AnswerContentWrapper>
          </AnswerContentSide>
        </HiddenContentBoxWithAnswerSlider>
      </TemplateSectionContainer>
    )
  }

  return (
    <PageWithBannerTitle>
      <BannerTitle>
        <ViewReportTitle>
          <TemplatePageTitle>
            {t('reportNameLabel')}
            <TemplateName>{reportDetail.item?.name}</TemplateName>
          </TemplatePageTitle>
          <TemplatePageTitle>
            {t('reportTemplateLabel')}
            <TemplateName>{reportDetail?.item?.template?.name}</TemplateName>
          </TemplatePageTitle>
          <Button to={`/company/${reportDetail?.item?.company?.id}`} color="secondary" size="large" isSimple>
            <FontAwesomeIcon icon="times" />
          </Button>
        </ViewReportTitle>
      </BannerTitle>

      <SectionsTableContainer>
        {map(reportDetail?.item?.sections, (section: UserSection, secIndex: number) =>
          renderSection(section, secIndex),
        )}
      </SectionsTableContainer>
    </PageWithBannerTitle>
  )
}

export default ReportView
