import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { text, bg, lighten, darken, fadedOpacity } from 'utils/colors'
import { SIDENAV_WIDTH, TITLEBAR_HEIGHT } from 'utils/appUnits'

interface NavItemProps {
  'data-active': boolean
  'data-temp'?: boolean
}

// app/App

export const AppContainer = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  margin: 0;

  grid-template-areas:
    'sidenav titlebar'
    'sidenav main';

  grid-template-columns: ${SIDENAV_WIDTH} 1fr;
  grid-template-rows: ${TITLEBAR_HEIGHT} 1fr;
`
export const MainContent = styled.div`
  grid-area: main;
  background: ${bg.main};
  color: ${text.main};
  overflow-y: auto;
  overflow-x: hidden;
`

// app/Titlebar

export const TitlebarContainer = styled.div`
  grid-area: titlebar;
  background: ${bg.titlebar};
  color: ${text.titlebar};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 2rem;
`
export const TitleIcon = styled.div`
  font-size: 1.4rem;
  color: ${text.titlebar};
  padding: 0.75rem;

  cursor: pointer;
  user-select: none;
  opacity: ${fadedOpacity};
  &:hover {
    opacity: 1;
  }
`
export const AvatarWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`

// app/Sidenav

export const SidenavContainer = styled.div`
  grid-area: sidenav;
  background: ${bg.nav};
  color: ${text.nav};
  height: 100vh;
  display: grid;
  grid-template-rows: ${TITLEBAR_HEIGHT} auto 4rem;
`
export const NavWrapper = styled.div``
export const NavItem = styled(Link)<NavItemProps>`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 2rem;
  color: ${text.nav};
  cursor: pointer;
  user-select: none;
  opacity: ${p => (p['data-temp'] ? '0.2' : p['data-active'] ? '1' : '0.7')};
  background: ${p => (p['data-active'] ? darken : 'transparent')};

  & > svg {
    margin-right: 1.5rem;
    font-size: 1.5rem;
  }

  &:hover {
    background: ${lighten};
  }
`

// SettingsModal

export const SettingsModalContainer = styled.div`
  width: 30rem;
  padding: 2rem;
  & > * {
    margin: 0 0 1rem;
  }
`
export const SettingsBtns = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem 0 0;
`
export const CloseIcon = styled.div`
  font-size: 1.5rem;
  color: ${text.inactive};
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${text.active};
  }
`
export const FormSuccessMsg = styled.div`
  padding: 5rem 2rem;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  color: ${text.success};
`
