import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from 'utils/auth0'
import { useTranslation } from 'react-i18next'
import { listTemplateOptions } from 'actions/templateActions'

import Form from 'components/Form/GoodForm'
import Spinner from 'components/Spinner'
import ReportsList from './ReportsList'
import UsersList from './UsersList'
import ErrorView from 'components/ErrorView'

import { PageContent, PageTitle, ContentBoxTitle } from 'components/styles'
import { CompanyDetailContent, ReportSettingsContainer, ReportsListWrapper, CompanySettingsWrapper } from './styles'
import { findCompany, updateCompany } from 'actions/companyActions'

interface CompanyViewProps {
  match: {
    params: {
      companyId: string
    }
  }
}

const CompanyView: React.FC<CompanyViewProps> = ({
  match: {
    params: { companyId },
  },
}: CompanyViewProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth0Context = useAuth0()
  const { companyDetail } = useSelector((state: any) => state.companies)
  const { templateOptions } = useSelector((state: any) => state.templates)
  // const { handleSubmit, register, errors, setValue } = useForm()

  React.useEffect(() => {
    dispatch(findCompany(auth0Context, companyId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!templateOptions.lastUpdated) dispatch(listTemplateOptions(auth0Context))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data: any): void => {
    const { templateId, daysToComplete } = data
    dispatch(
      updateCompany(auth0Context, companyDetail.item.id, { templateId, daysToComplete: parseInt(daysToComplete) }),
    )
  }

  if (companyDetail.isFetching) {
    return <Spinner />
  }

  let content
  if (companyDetail.error) {
    content = <ErrorView serverResponse={companyDetail.error} errorMsg={t('findCompanyError')} />
  } else if (companyDetail.isFetching) {
    content = <Spinner />
  } else {
    content = (
      <CompanyDetailContent>
        <UsersList companyId={companyId} />
        {companyDetail?.item?.status === 'ENABLED' ? (
          <ReportSettingsContainer>
            <ContentBoxTitle>{t('reportSettingsTitle')}</ContentBoxTitle>
            <CompanySettingsWrapper>
              {companyDetail?.isSaving ? (
                <Spinner />
              ) : (
                <Form
                  onSubmit={onSubmit}
                  errorMsg={t(companyDetail.error)}
                  isHideShowBtns
                  fields={[
                    {
                      type: 'select',
                      name: 'templateId',
                      options: templateOptions?.items,
                      initialValue: companyDetail?.item.template?.id,
                      ref: { required: true },
                      errors: { required: t('templateFieldRequired') },
                    },
                    {
                      type: 'number',
                      name: 'daysToComplete',
                      initialValue: companyDetail?.item.daysToComplete,
                      ref: { required: true, min: 0 },
                      errors: { required: t('daysToCompleteError'), min: t('daysToCompleteError') },
                    },
                  ]}
                  submitBtn={{ size: 'small', color: 'success', label: t('submitBtn') }}
                  cancelBtn={{ size: 'small', color: 'secondary', label: t('cancelBtn') }}
                />
              )}
              {companyDetail?.updateError ? (
                <ErrorView
                  serverResponse={companyDetail?.updateError}
                  errorMsg={t(companyDetail?.updateError)}
                ></ErrorView>
              ) : null}
            </CompanySettingsWrapper>
          </ReportSettingsContainer>
        ) : null}
        <ReportsListWrapper>
          <ReportsList companyId={companyId} />
        </ReportsListWrapper>
      </CompanyDetailContent>
    )
  }

  return (
    <PageContent>
      <PageTitle>{companyDetail?.item.name}</PageTitle>
      {content}
    </PageContent>
  )
}

export default CompanyView
