import { Report } from '../models/report'

import {
  LIST_REPORTS_LOADING,
  LIST_REPORTS_SUCCESS,
  LIST_REPORTS_FAILURE,
  FIND_REPORT_LOADING,
  FIND_REPORT_SUCCESS,
  FIND_REPORT_FAILURE,
} from 'actions/reportActions'

interface ReportState {
  reportDetail: {
    isFetching: boolean
    item: { id: string } | Report

    error?: string
    lastUpdated?: Date
  }

  reportList: {
    items: Report[]
    isFetching: boolean

    page?: number
    perPage?: number
    totalItems?: number

    error?: string
    lastUpdated?: Date
  }
}

const initState = {
  reportDetail: {
    isFetching: false,
    item: { id: '' },
  },

  reportList: {
    isFetching: false,
    items: [],
  },
}

export const reportReducer = (state = initState, action: any): ReportState => {
  switch (action.type) {
    case LIST_REPORTS_LOADING:
      return {
        ...state,

        reportDetail: { ...state.reportDetail },
        reportList: {
          ...state.reportList,

          error: undefined,
          isFetching: true,
        },
      }

    case LIST_REPORTS_SUCCESS:
      return {
        ...state,

        reportList: {
          items: action.payload.items,
          page: action.payload.page,
          perPage: action.payload.perPage,
          totalItems: action.payload.totalItems,

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case LIST_REPORTS_FAILURE:
      return {
        ...state,

        reportList: {
          ...state.reportList,

          error: action.payload.errorMsg,
          isFetching: false,
        },
      }

    case FIND_REPORT_LOADING:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,

          isFetching: true,
          error: undefined,
        },
      }

    case FIND_REPORT_SUCCESS:
      return {
        ...state,
        reportDetail: {
          item: {
            ...action.payload.report,
          },

          error: undefined,
          isFetching: false,
          lastUpdated: new Date(),
        },
      }

    case FIND_REPORT_FAILURE:
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,

          isFetching: false,
          error: action.payload.errorMsg,
        },
      }

    default:
      return state
  }
}
