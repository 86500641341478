import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { text } from 'utils/colors'

interface StatusBadgeProps {
  status: 'COMPLIANT' | 'NON_COMPLIANT' | 'PENDING' | 'OVERDUE' | 'NOT_APPLICABLE'
  size?: 'lg'
  count?: number
}

const StatusBadgeContainer = styled.div<StatusBadgeProps>`
  font-size: ${p => (p.size === 'lg' ? '1.2em' : '0.9em')};
  display: inline-block;
  background: ${p => text[p.status]};
  color: ${text.contrast};
  font-weight: 600;
  width: ${p => (p.size === 'lg' ? '9em' : 'auto')};
  padding: ${p => (p.size === 'lg' || p.count != null ? '0.5rem 0.5rem' : '0.25em 0.5em')};
  border-radius: 0.3em;
  text-align: center;
`
const CountWrapper = styled.div`
  display: inline-block;
  font-size: 1.2em;
  margin-right: 0.4em;
`

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, size, count }: StatusBadgeProps) => {
  const { t } = useTranslation()
  if (!status) return null
  return (
    <StatusBadgeContainer status={status} size={size} count={count}>
      {count != null && <CountWrapper>{count}</CountWrapper>}
      {t(status)}
    </StatusBadgeContainer>
  )
}

export default StatusBadge
