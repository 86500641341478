import styled from 'styled-components'
import { text, bg, colors } from 'utils/colors'
import { PAGE_MARGIN, TITLEBAR_HEIGHT, SIDENAV_WIDTH } from 'utils/appUnits'

export const PageContent = styled.div`
  width: calc(100% - (2 * ${PAGE_MARGIN}));
  max-width: 80rem;
  margin: 0 auto;
  padding: ${PAGE_MARGIN};
`
export const PageTitle = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin: 0 auto 1rem;
`
export const PageWithBannerTitle = styled.div`
  padding-top: ${TITLEBAR_HEIGHT};
`
export const BannerTitle = styled.div`
  position: fixed;
  top: ${TITLEBAR_HEIGHT};
  width: calc(100vw - ${SIDENAV_WIDTH});
  z-index: 100;
`
export const SubTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
`
export const ContextTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
`
export const ModalTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`
export const MiniTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
`
export const ContentBox = styled.div`
  box-shadow: 0 0 0.5rem 0 ${colors.shadow};
  background: ${bg.contentBox};
  color: ${text.contentBox};
  padding: 0;
  border-radius: 0.5rem;
  overflow-x: hidden;
`

export const ContentBoxLists = styled(ContentBox)`
  & > * {
    padding: 1rem;
  }
  & > *:last-child {
    padding: 1rem 1rem 2rem;
  }
`
export const ContentBoxTitle = styled.h1`
  font-size: 1.2rem;
  height: 1.5rem;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid ${bg.inactive};
  padding: 1rem 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ActionBtns = styled.div<{ isHidden?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  visibility: ${p => (p.isHidden ? 'hidden' : 'visible')};
  & > * {
    margin-left: 1rem;
  }
`
export const FormWrapper = styled.div<{ isOpen: boolean }>`
  background: ${bg.success};
  grid-column: 1 / -1;
  overflow: hidden;
  height: auto;
  padding: ${p => (p.isOpen ? '1rem' : '0 1rem')};
  margin: 0;
  max-height: ${p => (p.isOpen ? '30rem' : '0')};
  transition: all 0.3s ease-in-out;
`
interface ColumnHeadingProps {
  isLeftAligned?: boolean
  isFlex?: boolean
  isDoubleCol?: boolean
  size?: 'lg'
}
export const ColumnHeading = styled.div<ColumnHeadingProps>`
  font-size: ${p => (p.size === 'lg' ? '1.2rem' : '0.9rem')};
  font-weight: 600;
  color: ${text.main};
  justify-self: ${p => (p.isLeftAligned ? 'start' : 'center')};
  text-align: ${p => (p.isLeftAligned ? 'left' : 'center')};
  grid-column-end: ${p => (p.isDoubleCol ? 'span 2' : 'span 1')};
  display: ${p => (p.isFlex ? 'flex' : 'block')};
  justify-content: space-between;
  align-items: center;
  width: ${p => (p.isFlex ? '100%' : 'auto')};
  align-self: center;
`
export const ToggleIcon = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  user-select: none;
  line-height: 0;
  align-self: center;
  transform: ${p => (p.isOpen ? 'rotate(90deg)' : 'rotate(0)')};
  transition: transform 0.3s ease-in-out;
  font-size: 2rem;
  color: ${text.inactive};
`
