import React from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { map } from 'lodash'

import TriveLogo from 'components/TriveLogo'
import PoweredBy from 'components/PoweredBy'

import { SidenavContainer, NavWrapper, NavItem } from './styles'

interface SidenavProps {
  location?: any
}

const navItems = [
  {
    label: 'dashboardNav',
    path: '/',
    icon: <FontAwesomeIcon icon="chart-area" />,
  },
  {
    label: 'portfolioNav',
    path: '/portfolio',
    icon: <FontAwesomeIcon icon="building" />,
  },
  {
    label: 'orgSettingsNav',
    path: '/settings',
    icon: <FontAwesomeIcon icon="cog" />,
  },
  {
    label: 'componentLibraryNav',
    path: '/components',
    icon: <FontAwesomeIcon icon="book" />,
    isTemp: true,
  },
  {
    label: 'tempUserReportsList',
    path: '/userReportsList',
    icon: <FontAwesomeIcon icon="fire" />,
    isTemp: true,
  },
  {
    label: 'tempUserReportView',
    path: '/userReport',
    icon: <FontAwesomeIcon icon="ghost" />,
    isTemp: true,
  },
]

const Sidenav: React.FC<SidenavProps> = ({ location }: SidenavProps) => {
  const { t } = useTranslation()
  const [activePath, setActivePath] = React.useState(location.pathname)

  React.useEffect(() => {
    setActivePath(location.pathname)
  }, [location.pathname])

  return (
    <SidenavContainer>
      <TriveLogo />
      <NavWrapper>
        {map(navItems, nav => (
          <NavItem to={nav.path} key={`nav-${nav.path}`} data-active={nav.path === activePath} data-temp={nav.isTemp}>
            {nav.icon}
            {t(nav.label)}
          </NavItem>
        ))}
      </NavWrapper>
      <PoweredBy />
    </SidenavContainer>
  )
}

export default withRouter(Sidenav)
