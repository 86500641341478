import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { text } from 'utils/colors'
import { MiniTitle } from 'components/styles'

interface ErrorViewProps {
  errorMsg?: string
  serverResponse?: string
  hideContactMsg?: boolean
}

const ErrorViewContainer = styled(MiniTitle)`
  color: ${text.error};
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-content: center;
`

const ErrorView: React.FC<ErrorViewProps> = ({ errorMsg, serverResponse, hideContactMsg }: ErrorViewProps) => {
  const { t } = useTranslation()

  return (
    <ErrorViewContainer>
      <div>
        {errorMsg || t('genericErrorMsg')}
        <br />
        {serverResponse ? (
          <>
            <br />
            Error Message:
            <br />
          </>
        ) : null}
        <br />
        <br />
        {hideContactMsg ? '' : t('contactSupportMsg')}
      </div>
    </ErrorViewContainer>
  )
}

export default ErrorView
